import Vue from 'vue';
import App from './App.vue';
import router from './router';
import animated from 'animate.css';
import '@/assets/style/index.scss';
import 'video.js/dist/video-js.css';
import '@/router/nprogress';
import '@/assets/js/restrem.js';
import 'swiper/dist/css/swiper.css';

Vue.config.productionTip = false;
Vue.prototype.imageCdnUrl = 'https://cdn.k2games.cc/burstspace'
Vue.use(animated);

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
