<template>
  <div class="order">
    <swiper
      id="orderSwiper"
      class="stop-swiping"
      :options="swiperOption"
      ref="orderSwiper">
      <swiper-slide class="first-swiper animate__animated animate__fadeIn">
        <div class="logo"></div>
        <div
          class="website-btn"
          @click="handleHome"></div>
        <div
          class="logo-main animate__animated animate__fadeInLeft"
          v-show="firstShow">
          <div class="logo-place"></div>
          <div
            class="btn-google"
            @click="handleClickPlay('register1_button_point')"></div>
        </div>
      </swiper-slide>
      <swiper-slide class="two-swiper">
        <div class="bg-right-top"></div>
        <div class="bg-left-bottom"></div>
        <div class="two-swiper-main">
          <div
            class="swiper-main-bg animate__animated animate__fadeInRight"
            v-show="twoShow"></div>
          <div
            v-show="twoShow"
            id="cardLists"
            :class="[
              'card-lists',
              'animate__animated',
              'animate__fadeInUp',
              `card-lists-active-${activeIndexs}`,
            ]">
            <ul class="">
              <li
                v-for="(item, index) in cardLists"
                :key="index"
                :class="[
                  item.activeIndex === 1
                    ? `${item.name}-active shadow-color${index + 1}`
                    : `${item.name}`,
                  'card-item',
                ]">
                <div class="item-img"></div>
                <div class="animate-border">
                  <i></i>
                  <i></i>
                </div>
              </li>
            </ul>
            <div
              class="btn-google"
              @click="handleClickPlay('register2_button_point')"></div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide class="three-swiper">
        <div class="three-swiper-main">
          <swiper
            :options="swiperBannerOption"
            ref="swiperBanner">
            <swiper-slide
              class="new-item"
              v-for="(item, index) in swiperBannerLists"
              :key="index">
              <img
                :src="`${imageCdnUrl}${item.pic}`"
                style="width: 100%; height: 100%" />
            </swiper-slide>
          </swiper>
          <div
            v-show="threeShow"
            class="swiper-button-prev animate__animated animate__fadeInLeft"
            slot="button-prev"
            @click="swiperPrev"></div>
          <div
            v-show="threeShow"
            class="swiper-button-next animate__animated animate__fadeInRight"
            slot="button-next"
            @click="swiperNext"></div>
        </div>
        <div
          class="toTop animate__animated animate__fadeInUp"
          @click="handleTop"></div>
      </swiper-slide>
    </swiper>
    <div class="toogle-item">
      <div
        v-for="(item, index) in linklist"
        :key="index"
        :class="`${item.name}`"
        @click="handleClick(item)"></div>
    </div>
    <!-- <div
      class="footer"
      id="footer">
      <div
        v-for="(item, index) in linklist"
        :key="index"
        :class="['center-item', `${item.name}`]"
        @click="handleClick(item)"></div>
     
    </div> -->
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import { getBtnlog } from '@/api/index';

export default {
  name: 'order',
  data() {
    return {
      curPage: 0,
      firstShow: true,
      twoShow: false,
      threeShow: false,
      swiperOption: {
        direction: 'vertical', // 竖向滚动
        grabCursor: true,
        autoplay: false,
        mousewheel: true,
        autoHeight: true,
        noSwiping: true,
        noSwipingClass: 'stop-swiping',
        height: window.innerHeight,
        preventInteractionOnTransition: true,
        on: {
          slideChangeTransitionStart: () => {
            const active = this.orderSwiper.activeIndex;
            if (active === 0) {
              this.firstShow = true;
              this.twoShow = false;
              this.threeShow = false;
            } else if (active === 1) {
              this.firstShow = false;
              this.twoShow = true;
              this.threeShow = false;
            } else {
              this.bannerSwiper.activeIndex = 0;
              this.firstShow = false;
              this.twoShow = false;
              this.threeShow = true;
            }
          },
          resize: () => {
            this.orderSwiper.params.height = window.innerHeight;
            this.orderSwiper.update(true);
          },
        },
      },
      swiperBannerOption: {
        allowTouchMove: false,
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
      },
      cardLists: [
        {
          name: 'nugget',
          activeIndex: 1, // 解锁
        },
        {
          name: 'orb',
          activeIndex: 1, // 未解锁
        },
        {
          name: 'coin',
          activeIndex: 1,
        },
        {
          name: 'booster',
          activeIndex: 1,
        },
        {
          name: 'coin2',
          activeIndex: 1,
        },
        {
          name: 'alfred',
          activeIndex: 1,
        },
      ],
      linklist: [
        {
          name: 'googleplay',
          url: 'https://play.google.com/store/apps/details?id=cc.k2games.burstspace',
          type: 'google_button_point',
        },
        {
          name: 'tiktok',
          url: 'https://www.tiktok.com/@burstspace',
          type: 'tiktok_button_point',
        },
        {
          name: 'facebook',
          url: 'https://www.facebook.com/burstspacegroup',
          type: 'facebook_button_point',
        },
        {
          name: 'youtobe',
          url: 'https://www.youtube.com/@burstspaceglobal',
          type: 'youtube_button_point',
        },
      ],
      swiperBannerLists: [
        { pic: '/assets/images/pc/swiper-one.png' },
        { pic: '/assets/images/pc/swiper-two.png' },
        { pic: '/assets/images/pc/swiper-three.png' },
        { pic: '/assets/images/pc/swiper-four.png' },
        { pic: '/assets/images/pc/swiper-five.png' },
      ],
      activeIndexs: 'six',
      // screenHeight: 0,
    };
  },
  components: {
    swiper,
    swiperSlide,
  },
  computed: {
    orderSwiper() {
      return this.$refs.orderSwiper.swiper;
    },
    bannerSwiper() {
      return this.$refs.swiperBanner.swiper;
    },
  },
  methods: {
    swiperPrev() {
      this.bannerSwiper.slidePrev();
    },
    swiperNext() {
      this.bannerSwiper.slideNext();
    },
    handleClickPlay(type) {
      this.handelBtnlog(type);
      window.open(
        'https://play.google.com/store/apps/details?id=cc.k2games.burstspace'
      );
    },
    handleClick(item) {
      this.handelBtnlog(item.type);
      if (item.url !== '') {
        window.open(item.url);
      }
    },
    handleTop() {
      this.curPage = 0;
      this.orderSwiper.slideTo(this.curPage, 300, false);
      this.firstShow = true;
      this.showFooter(false);
    },
    handelBtnlog(type) {
      getBtnlog({
        game_id: 1,
        event: type,
      }).then((res) => {
        if (res.code === 200) {
          console.log('记录成功！');
        } else {
          console.log('记录失败！');
        }
      });
    },
    handleHome() {
      this.$router.push('/home');
    },
  },
  created() {},
  mounted() {
    this.orderSwiper.autoplay.stop();
    this.handelBtnlog('visitor_page_view');
    // this.screenHeight = window.innerHeight;
  },
};
</script>

<style lang="scss" scoped>
$activeIndex: 'six';
$items: (
  nugget: #fff,
  // orb: #24e9b8,
  // coin: #3482f8,
  // booster: #cb24e9,
  // coin2: #e98524,
  // alfred: #f8ec34,
  orb: #6dffdb,
  coin: #8cc8ff,
  booster: #e867ff,
  coin2: #ffb56e,
  alfred: #fff66b,
);
$rgbs: rgba(255, 255, 255, 0.2), rgba(36, 233, 184, 0.2),
  rgba(52, 130, 248, 0.2), rgba(203, 36, 233, 0.2), rgba(233, 133, 36, 0.2),
  rgba(248, 236, 52, 0.2);
$channels: 'googleplay', 'facebook', 'tiktok', 'youtobe';

@each $i, $c in $items {
  .#{$i} {
    .item-img {
      background: url('#{$cdnUrl}/assets/images/pc/#{$i}.png') no-repeat;
      background-size: 100%;
      width: 1.14rem;
      height: 1.44rem;
      border-radius: 10px 0 0 0;
    }
  }
  .#{$i}-active {
    border-radius: 10px 0 0 0;
    .item-img {
      background: url('#{$cdnUrl}/assets/images/pc/#{$i}-active.png')
        no-repeat
        center
        center
        transparent;
      width: 1.08rem !important;
      height: 1.4rem !important;
      background-size: 98%;
    }
    .animate-border {
      position: absolute;
      top: 0px;
      width: 100%;
      height: 100%;

      &::before,
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 6px;
        z-index: 999;
      }
      i {
        position: absolute;
        display: inline-block;
        height: 100%;
        width: 6px;
        z-index: 999;
      }
      &::before {
        top: -0px;
        left: -100%;
        background-image: linear-gradient(
          90deg,
          transparent,
          #{$c},
          transparent
        );
        animation: left 4s linear infinite;
      }
      i:nth-child(1) {
        top: -100%;
        right: -0px;
        background-image: linear-gradient(
          180deg,
          transparent,
          #{$c},
          transparent
        );
        /* 动画名称  动画持续时间  动画渲染函数 动画延迟时间 动画执行次数 */
        animation: top 4s linear 1s infinite;
      }
      &::after {
        bottom: -0px;
        right: -100%;
        background-image: linear-gradient(
          -90deg,
          transparent,
          #{$c},
          transparent
        );
        animation: right 4s linear 2s infinite;
      }
      i:nth-child(2) {
        bottom: -100%;
        left: -0px;
        background-image: linear-gradient(
          180deg,
          transparent,
          #{$c},
          transparent
        );
        // linear-gradient(135deg, transparent 5px, #f8ec34 0);
        animation: bottom 4s linear 3s infinite;
      }
    }
  }
}
@for $i from 1 through 6 {
  .shadow-color#{$i} {
    background-color: nth($rgbs, $i);
    box-shadow: 0 0 0.06rem 0.6rem nth($rgbs, $i) inset,
      0 0.06rem 0.2rem 0.06rem nth($rgbs, $i);
    .animate-border {
      box-shadow: 0 0.2rem 0.2rem 0.2rem nth($rgbs, $i);
    }
  }
}

.order {
  width: 100%;
  height: 100%;
  background-color: #000;
  .swiper-container {
    width: 100%;
    overflow: hidden;
  }
  .website-btn {
    background: url('#{$cdnUrl}/assets/images/pc/weisite-btn.png') no-repeat;
    background-size: 100%;
    width: 1.1rem;
    height: 0.29rem;
    position: absolute;
    right:0.25rem;
    top:0.44rem;
  }
  .btn-google {
    width: 2.92rem;
    height: 0.76rem;
    background: url('#{$cdnUrl}/assets/images/pc/google-btn.png') no-repeat;
    background-size: 100%;
    margin: 0 auto;
    cursor: pointer;
  }
  .stop-swiping {
    width: 100%;
    height: 100%;
    background: url('#{$cdnUrl}/assets/images/pc/two-bg.png') no-repeat top
      center;
    background-size: cover;
  }
  .first-swiper {
    width: 100%;
    background: url('#{$cdnUrl}/assets/images/pc/first-bg.png') no-repeat bottom
      center;
    background-size: cover;
    .logo {
      width: 1.98rem;
      height: 1.4rem;
      background: url('#{$cdnUrl}/assets/images/pc/logo.png') no-repeat;
      background-size: 100%;
      margin: 1.03rem 0 0 0.88rem;
    }
    .logo-main {
      margin: 0.34rem 0 0 1.65rem;
      width: 7.24rem;
      .logo-place {
        width: 7.24rem;
        height: 2.24rem;
        background: url('#{$cdnUrl}/assets/images/pc/logo-place.png') no-repeat;
        background-size: 100%;
        margin-bottom: 0.1rem;
      }
    }
  }
  .two-swiper {
    .bg-right-top {
      position: absolute;
      right: 1.82rem;
      top: 0.97rem;
      width: 0.92rem;
      height: 0.53rem;
      background: url('#{$cdnUrl}/assets/images/pc/right-top.png') no-repeat;
      background-size: 100%;
    }
    .bg-left-bottom {
      position: absolute;
      left: 2.21rem;
      bottom: 0.98rem;
      width: 0.92rem;
      height: 0.53rem;
      background: url('#{$cdnUrl}/assets/images/pc/left-bottom.png') no-repeat;
      background-size: 100%;
    }
    .two-swiper-main {
      width: 15.07rem;
      height: 8.79rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .swiper-main-bg {
        width: 12.01rem;
        height: 6.89rem;
        background: url('#{$cdnUrl}/assets/images/pc/swiper-two-main.png')
          no-repeat right center;
        background-size: 100%;
      }

      .card-lists {
        width: 9.27rem;
        height: 3.1rem;
        // background: url('#{$cdnUrl}/images/pc/items-zero.png') no-repeat
        //   bottom center;
        // background-size: 100%;
        position: absolute;
        z-index: 999;
        top: 2.85rem;
        left: 1rem;
        // &-active {
        //  background: url('#{$cdnUrl}/assets/images/pc/items-six.png')
        //     no-repeat bottom center !important;
        //   background-size: 100% !important;
        // }
        ul {
          display: flex;
          flex-wrap: nowrap;
          align-items: flex-end;
          margin-left: 0.55rem;
          .card-item {
            margin: 0 0.07rem 0.8rem 0.07rem;
            position: relative;
            overflow: hidden;
            padding: 0.03rem;
            display: flex;
            align-items: center;
            justify-content: center;
            .item-img {
              width: 1.14rem;
              height: 1.44rem;
            }
          }
          .alfred,
          .alfred-active {
            .item-img {
              width: 1.55rem !important;
              height: 1.98rem !important;
            }
          }
        }
      }
      .card-lists-active-#{$activeIndex} {
        background: url('#{$cdnUrl}/assets/images/pc/items-#{$activeIndex}.png')
          no-repeat bottom center;
        background-size: 100%;
      }
      .btn-google {
        width: 2.29rem;
        height: 0.6rem;
        margin: 0.8rem 0 0 2.2rem;
      }
    }
  }
  .three-swiper {
    display: flex;
    align-items: center;
    justify-content: center;
    .three-swiper-main {
      width: 14.2rem;
      height: 7.58rem;
      position: relative;
    }
    .swiper-button-prev,
    .swiper-button-next {
      width: 1.19rem;
      height: 0.69rem;
      position: absolute;
      top: 50%;
      transform: translate(0%, -50%);
    }
    .swiper-button-prev {
      background: url('#{$cdnUrl}/assets/images/pc/arr-left.png') no-repeat;
      background-size: 100%;
      left: -0.58rem;
    }
    .swiper-button-next {
      background: url('#{$cdnUrl}/assets/images/pc/arr-right.png') no-repeat;
      background-size: 100%;
      right: -0.58rem;
    }
  }
  // .footer {
  //   position: absolute;
  //   bottom: -2rem;
  //   width: 100%;
  //   height: 2rem !important;
  //  background: url('#{$cdnUrl}/assets/images/pc/footer-bg.png') no-repeat;
  //   background-size: 100%;
  //   z-index: 999;
  //   display: flex;
  //   flex-wrap: nowrap;
  //   justify-content: center;
  //   align-items: center;
  //   .center-item {
  //     width: 1.81rem;
  //     height: 0.56rem;
  //     margin: 0 0.22rem;
  //   }

  //   @each $i in $channels {
  //     .#{$i} {
  //      background: url('#{$cdnUrl}/assets/images/pc/#{$i}.png') no-repeat;
  //       background-size: 100%;
  //     }
  //   }
  // }
  .toogle-item {
    position: fixed;
    right: 0.47rem;
    top: 1.08rem;
    z-index: 999;
    div {
      width: 0.5rem;
      height: 0.5rem;
      margin-bottom: 0.15rem;
      transition: transform 0.5s ease;
      &:hover {
        transform: scale(1.2);
      }
    }

    @each $i in $channels {
      .#{$i} {
        background: url('#{$cdnUrl}/assets/images/pc/#{$i}.png') no-repeat;
        background-size: 100%;
      }
    }
  }
  @keyframes left {
    0% {
      left: -100%;
    }
    50%,
    100% {
      left: 100%;
    }
  }
  @keyframes top {
    0% {
      top: -100%;
    }
    50%,
    100% {
      top: 100%;
    }
  }
  @keyframes right {
    0% {
      right: -100%;
    }
    50%,
    100% {
      right: 100%;
    }
  }
  @keyframes bottom {
    0% {
      bottom: -100%;
    }
    50%,
    100% {
      bottom: 100%;
    }
  }
  .toTop {
    width: 0.61rem;
    height: 0.76rem;
    background: url('#{$cdnUrl}/assets/images/pc/to_top.png') no-repeat;
    background-size: 100%;
    position: absolute;
    bottom: 0.6rem;
    right: 0.6rem;
    z-index: 999;
    cursor: pointer;
  }
}
</style>
