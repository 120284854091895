import Vue from 'vue';
import VueRouter from 'vue-router';
import { isMobile } from '@/assets/js/utils';
import Home from '../views/pc/Home.vue';
import Agreements from '../views/pc/Agreements.vue';
import Info from '../views/pc/Info.vue';
import Newslist from '../views/pc/Newslist.vue';
import Order from '../views/pc/Order.vue';
import MHome from '../views/mobile/Home.vue';
import MAgreements from '../views/mobile/Agreements.vue';
import MInfo from '../views/mobile/Info.vue';
import MNewslist from '../views/mobile/Newslist.vue';
import MOrder from '../views/mobile/Order.vue';

Vue.use(VueRouter);

let routes = [];
if (isMobile()) {
  routes = [
    {
      path: '/',
      redirect: '/home',
    },
    {
      path: '/home',
      name: 'Home',
      component: MHome,
    },
    {
      path: '/agreements',
      name: 'Agreements',
      component: MAgreements,
    },
    {
      path: '/info',
      name: 'Info',
      component: MInfo,
    },
    {
      path: '/newslist',
      name: 'Newslist',
      component: MNewslist,
    },
    {
      path: '/order',
      name: 'order',
      component: MOrder,
    },
  ];
} else {
  routes = [
    {
      path: '/',
      redirect: '/home',
    },
    {
      path: '/home',
      name: 'Home',
      component: Home,
    },
    {
      path: '/agreements',
      name: 'Agreements',
      component: Agreements,
    },
    {
      path: '/info',
      name: 'Info',
      component: Info,
    },
    {
      path: '/newslist',
      name: 'Newslist',
      component: Newslist,
    },
    {
      path: '/order',
      name: 'Order',
      component: Order,
    },
  ];
}

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from) {
    if (to.name !== 'Home') {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
