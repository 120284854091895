<template>
  <div class="home-mobile">
    <download-top></download-top>
    <div class="part part-one">
      <div class="logo"></div>
      <div
        class="play-video"
        @click="playVedio">
        <div class="play-circle animate"></div>
        <div class="play-btn"></div>
      </div>
      <div
        class="play-bg"
        v-show="isShow"></div>
      <video-player
        ref="videoPlayer"
        :isShow="isShow"
        :ispc="false"
        :width="'3.2rem'"
        :height="'1.8rem'"
        @close="closePlayer"></video-player>

      <div class="link">
        <div
          v-for="(item, index) in channelList"
          :key="index"
          :class="['link-btn', item.name]"
          @click="handleChannel(item)"></div>
      </div>
    </div>
    <div
      class="part part-two"
      @mouseleave="mouseenter(activeIndex)">
      <div class="title title-two"></div>
      <swiper
        :option="swiperOption"
        circular="true"
        loop="true"
        ref="mySwiper">
        <swiper-slide
          v-for="(item, index) in bannerlist"
          :key="index">
          <img
            :src="`${imageCdnUrl}${item.imgSrc}`"
            :class="['banner-img', `banner-img-${item.name}`]" />
          <div class="info-box">
            <div class="info-name">
              <div :class="`info-name-${item.name}`"></div>
              <div :class="['gif', `gif-${item.name}`]"></div>
            </div>
            <div class="line"></div>
            <span>{{ item.info }}</span>
          </div>
        </swiper-slide>
      </swiper>
      <div
        class="swiper-pagination"
        slot="pagination">
        <div
          v-for="(item, index) in bannerlist"
          :key="index"
          :class="[
            activeIndex === index
              ? `btn-${item.name}-active`
              : `btn-${item.name}`,
            'btn',
          ]"
          @click="mouseenter(index)"
          @touchend="mouseleave()"></div>
      </div>
    </div>
    <div class="part-three">
      <div class="title title-three"></div>
      <swiper
        class="myNewsSwiper"
        :option="newsSwiperOption"
        ref="myNewsSwiper">
        <swiper-slide
          class="new-item"
          v-for="(item, index) in newsBanner"
          :key="index">
          <img
            :src="item.pic"
            style="width: 100%; height: 100%"
            @click="handelChangeUrl(item)" />
        </swiper-slide>
      </swiper>
      <div
        class="swiper-button-prev"
        slot="button-prev"
        @click="swipePrev"
        @touchend="newsMouseleave()"></div>
      <div
        class="swiper-button-next"
        slot="button-next"
        @click="swipeNext"
        @touchend="newsMouseleave()"></div>
      <div
        class="swiper-pagination"
        slot="pagination">
        <div
          v-for="(item, index) in newsBanner"
          :key="index"
          :class="[newsActive === index ? `btn-active` : '', 'btn']"
          @click="newsMouseenter(index)"
          @touchend="newsMouseleave(index)"></div>
      </div>
      <div class="new-list">
        <div class="new-list-tab">
          <div
            v-for="(item, index) in tabList"
            :key="index"
            class="tab-items"
            :class="tabIndex === index ? 'tabactive' : ''"
            @click="tabClick(index)">
            {{ item.name }}
          </div>
        </div>
        <div class="lists">
          <div
            class="list-item"
            v-for="(item, index) in newslist"
            :key="index"
            @click="handleItem(item)">
            <div class="list-item-left">
              <label>[{{ tabList[tabIndex].name }}]</label>
              <span class="text">
                {{ item.name }}
              </span>
              <div
                :class="[
                  'item-icon',
                  item.flag == 1
                    ? 'item-hot'
                    : item.flag == 2
                    ? 'item-new'
                    : '',
                ]"></div>
            </div>
            <div class="time">{{ item.published_at }}</div>
          </div>
        </div>
        <div
          class="more"
          @click="handleMore"></div>
        <div class="center">
          <div
            v-for="(item, index) in linklist"
            :key="index"
            :class="['center-item', `center-${item.name}`]"
            @click="handleClick(item)"></div>
        </div>
        <back-to-top :ispc="false"></back-to-top>
      </div>
    </div>
    <index-footer :ispc="false"></index-footer>
  </div>
</template>

<script>
import videoPlayer from '@/components/videoPlayer/index';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import BackToTop from '@/components/BackToTop/index';
import downloadTop from '@/components/download-top/index';
import IndexFooter from '@/components/Footer/index';
import { getBannerLists, getArticleLists } from '@/api/index';
import { isExternal } from '@/assets/js/utils';

export default {
  name: 'home-mobile',
  data() {
    return {
      isShow: false,
      bannerlist: [
        {
          name: 'one',
          imgSrc: '/assets/images/mobile/img_bs_ren1.png',
          info: 'Launches multiple feathers to pierce enemies, enemies hit by feathers will pause for a short time.',
        },
        {
          name: 'two',
          info: "Nine-Tails Sweep: When shooting, use the fox tail to sweep the enemy and reduce the enemy's attack strength",
          imgSrc: '/assets/images/mobile/img_bs_ren2.png',
        },
        {
          name: 'three',
          info: 'After rolling, it launches a shock wave centered on itself, causing damage to enemies and recovering part of its rolling energy',
          imgSrc: '/assets/images/mobile/img_bs_ren3.png',
        },
        {
          name: 'four',
          info: 'When transformed, launches Ship Collision to the front and creates a vortex after the collision, continuously dealing damage, and draws enemies.',
          imgSrc: '/assets/images/mobile/img_bs_ren4.png',
        },
        {
          name: 'five',
          info: 'Warrior Matchless: When shooting, use the painted halberd to perform a triple action; At the same time, it restores 5% of its maximum HP; the damage of the skill is greatly increased during the transformation',
          imgSrc: '/assets/images/mobile/img_bs_ren5.png',
        },
      ],
      channelList: [
        {
          name: 'android',
          url: 'https://play.google.com/store/apps/details?id=cc.k2games.burstspace',
        },
        {
          name: 'ios',
          url: '',
        },
      ],
      swiperOption: {
        loop: true,
        allowTouchMove: false,
        initialSlide: 1,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          paginationClickable: true,
        },
        observer: true,
        observerParents: true,
      },
      newsSwiperOption: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
      timer: null,
      newsTimer: null,
      activeIndex: 0,
      tabList: [
        { name: 'LATEST' },
        {
          name: 'NEWS',
        },
        {
          name: 'ACTIVITY',
        },
        {
          name: 'NOTICE',
        },
      ],
      newslist: [],
      newsBanner: [],
      tabIndex: 0,
      newsActive: 0,
      linklist: [
        {
          name: 'facebook',
          url: 'https://www.facebook.com/burstspacegroup',
        },
        {
          name: 'youtube',
          url: 'https://www.youtube.com/@burstspaceglobal',
        },
        {
          name: 'tiktok',
          url: 'https://www.tiktok.com/@burstspace',
        },
      ],
    };
  },
  components: {
    videoPlayer,
    swiper,
    swiperSlide,
    BackToTop,
    downloadTop,
    IndexFooter,
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
    myNewsSwiper() {
      return this.$refs.myNewsSwiper.swiper;
    },
  },
  destroyed() {
    clearInterval(this.timer);
    clearInterval(this.newsTimer);
  },
  mounted() {
    this.getArticleLists();
    this.getBannerLists();
    this.mouseleave();
    const vm = this;
    this.swiper.on('touchEnd', function (swiper) {
      clearInterval(vm.timer);
      vm.$nextTick(() => {
        vm.activeIndex = vm.swiper.activeIndex;
      });
      vm.mouseleave();
    });
    this.myNewsSwiper.on('touchEnd', function (swiper) {
      clearInterval(vm.newsTimer);
      vm.$nextTick(() => {
        vm.newsActive = vm.myNewsSwiper.activeIndex;
      });
      vm.newsMouseleave();
    });
  },
  methods: {
    handleChannel(item) {
      if (item.url !== '') {
        window.open(item.url);
      }
    },
    playVedio() {
      this.isShow = true;
      this.$refs.videoPlayer.playVideo();
    },
    closePlayer() {
      this.isShow = false;
    },
    mouseenter(index) {
      clearInterval(this.timer);
      this.activeIndex = index;
      this.swiper.slideTo(index, 10, false);
    },
    autoplay() {
      if (0 < this.activeIndex < 5) {
        this.activeIndex++;
        if (this.activeIndex >= 5) {
          this.activeIndex = 0;
        }
      }
      this.swiper.slideTo(this.activeIndex, 10, false);
    },
    newsAutoplay() {
      if (0 < this.newsActive < this.newsBanner.length) {
        this.newsActive++;
        if (this.newsActive >= this.newsBanner.length) {
          this.newsActive = 0;
        }
      }
      this.myNewsSwiper.slideTo(this.newsActive, 10, false);
    },
    mouseleave() {
      this.timer = setInterval(() => {
        this.autoplay();
      }, 3000);
    },
    newsMouseleave() {
      this.newsTimer = setInterval(() => {
        this.newsAutoplay();
      }, 3000);
    },
    newsMouseenter(index) {
      clearInterval(this.newsTimer);
      this.newsActive = index;
      this.myNewsSwiper.slideTo(index, 10, false);
    },
    tabClick(index) {
      this.tabIndex = index;
      this.getArticleLists(index);
    },
    swipePrev() {
      clearInterval(this.newsTimer);
      this.myNewsSwiper.slidePrev();
      this.newsActive = this.myNewsSwiper.activeIndex;
    },
    swipeNext() {
      clearInterval(this.newsTimer);
      this.myNewsSwiper.slideNext();
      this.newsActive = this.myNewsSwiper.activeIndex;
    },
    handleClick(item) {
      if (item.url !== '') {
        window.open(item.url);
      }
    },
    handleItem(item) {
      this.$router.push({
        path: '/info',
        query: {
          id: item.id,
        },
      });
    },
    getBannerLists() {
      getBannerLists({
        game_id: 1,
        page: 1,
        limit: 10,
      }).then((res) => {
        this.newsBanner = res.data.list;
        if (this.newsBanner > 2) {
          this.newsMouseleave();
        }
      });
    },
    getArticleLists(type) {
      this.newslist = [];
      getArticleLists({
        game_id: 1,
        type: type,
        page: 1,
        limit: 5,
      }).then((res) => {
        res.data.list.forEach((v) => {
          v.published_at = v.published_at.substring(0, 10);
        });
        this.newslist = res.data.list;
      });
    },
    handleMore() {
      this.$router.push('/newslist');
    },
    handelChangeUrl(item) {
      if (item.url_type === 2) {
        let url = '';
        isExternal(item.url) ? (url = item.url) : (url = `http://${item.url}`);
        window.open(url, '_blank');
      } else {
        this.$router.push(item.url);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home-mobile {
  overflow: hidden;
  .part {
    width: 100%;
    height: 6.67rem;
  }
  .part-one {
    background: url('#{$cdnUrl}/assets/images/mobile/bg_bs_bg.png') no-repeat;
    background-size: cover;
    padding-top: 1rem;
    position: relative;
    .logo {
      width: 1.41rem;
      height: 0.975rem;
      background: url('#{$cdnUrl}/assets/images/mobile/img_bs_logo.png')
        no-repeat;
      background-size: 100%;
      margin: 0 auto;
    }
    .play-video {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }
    .play-circle {
      width: 0.555rem;
      height: 0.55rem;
      background: url('#{$cdnUrl}/assets/images/mobile/play-circle.png')
        no-repeat;
      background-size: 100%;
    }
    .play-btn {
      width: 0.205rem;
      height: 0.25rem;
      background: url('#{$cdnUrl}/assets/images/mobile/play-btn.png') no-repeat;
      background-size: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-left: 0.015rem;
    }

    .play-bg {
      width: 3.26rem;
      height: 2.15rem;
      background: url('#{$cdnUrl}/assets/images/mobile/img_bs_spk.png')
        no-repeat;
      background-size: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 100;
      pointer-events: none;
    }

    .link {
      position: absolute;
      left: 50%;
      bottom: 0.6rem;
      transform: translate(-50%, 0%);
    }
    .link-btn {
      width: 1.63rem;
      height: 0.45rem;
      cursor: pointer;
    }
    .android {
      background: url('#{$cdnUrl}/assets/images/pc/android.png') no-repeat;
      background-size: 100%;
    }
    .ios {
      margin-top: 0.2rem;
      background: url('#{$cdnUrl}/assets/images/pc/ios.png') no-repeat;
      background-size: 100%;
    }
  }
  .title {
    width: 1.6rem;
    height: 0.45rem;
    margin-left: 0.12rem;
  }

  .title-two {
    background: url('#{$cdnUrl}/assets/images/mobile/img_bs_title.png')
      no-repeat;
    background-size: 100%;
  }
  .part-two {
    background: url('#{$cdnUrl}/assets/images/mobile/part-two-bg.png') no-repeat;
    background-size: cover;
    padding-top: 0.22rem;
    position: relative;
    .swiper-container {
      height: calc(100% - 0.445rem);
      overflow: visible;
    }
    .info-box {
      padding: 0.225rem;
      position: absolute;
      bottom: 0.2rem;
      width: 100%;
      .info-name {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: baseline;
      }
      .info-name-one {
        width: 1.645rem;
        height: 0.27rem;
        background: url('#{$cdnUrl}/assets/images/mobile/img_bs_ren_1Ashoka.png')
          no-repeat;
        background-size: 100%;
      }
      .info-name-two {
        width: 2.08rem;
        height: 0.19rem;
        background: url('#{$cdnUrl}/assets/images/mobile/img_bs_ren_2Tamamo-no-mae.png')
          no-repeat;
        background-size: 100%;
      }
      .info-name-three {
        width: 1.84rem;
        height: 0.215rem;
        background: url('#{$cdnUrl}/assets/images/mobile/img_bs_ren_3joanofarc.png')
          no-repeat;
        background-size: 100%;
      }
      .info-name-four {
        width: 1.55rem;
        height: 0.225rem;
        background: url('#{$cdnUrl}/assets/images/mobile/img_bs_ren_4Victoria.png')
          no-repeat;
        background-size: 100%;
      }
      .info-name-five {
        width: 1.23rem;
        height: 0.275rem;
        background: url('#{$cdnUrl}/assets/images/mobile/img_bs_ren_5LvBu.png')
          no-repeat;
        background-size: 100%;
      }
      .line {
        width: 100%;
        height: 0.15rem;
        background: url('#{$cdnUrl}/assets/images/mobile/img_bs_xian.png')
          no-repeat;
        background-size: 100%;
        margin-top: 0.16rem;
      }
      .gif {
        width: 1rem;
        height: 0.9rem;
      }
      .gif-one {
        background: url('#{$cdnUrl}/assets/images/pc/ayw.gif') no-repeat;
        background-size: 100%;
      }
      .gif-two {
        background: url('#{$cdnUrl}/assets/images/pc/yzq.gif') no-repeat;
        background-size: 100%;
        height: 1rem;
      }
      .gif-three {
        background: url('#{$cdnUrl}/assets/images/pc/zd.gif') no-repeat;
        background-size: 134%;
        width: 1.4rem;
        height: 1.5rem;
      }
      .gif-four {
        background: url('#{$cdnUrl}/assets/images/pc/wdly.gif') no-repeat;
        background-size: 100%;
        height: 0.85rem;
      }
      .gif-five {
        background: url('#{$cdnUrl}/assets/images/pc/lb.gif') no-repeat;
        background-size: 109%;
        width: 1.5rem;
        height: 1.1rem;
      }
      span {
        color: #fff;
        font-size: 0.12rem;
      }
    }
    .banner-img {
      width: 100%;
    }
    .banner-img-one {
      margin-top: 0.17rem;
    }
    .banner-img-two {
      margin-top: 0.17rem;
    }
    .banner-img-three {
      margin-top: -0.43rem;
    }
    .banner-img-four {
      margin-top: 0.1rem;
    }
    .banner-img-five {
      padding-top: -0.04rem;
    }
    .swiper-pagination {
      position: absolute;
      top: 40%;
      left: 0.16rem;
      transform: translate(0%, -50%);
      z-index: 999;
      .btn {
        width: 0.65rem;
        height: 0.65rem;
        cursor: pointer;
      }
      .btn-one {
        background: url('#{$cdnUrl}/assets/images/mobile/btn_one.png') no-repeat;
        background-size: 100%;
      }
      .btn-one-active {
        background: url('#{$cdnUrl}/assets/images/mobile/btn_one_active.png')
          no-repeat;
        background-size: 100%;
      }
      .btn-two {
        background: url('#{$cdnUrl}/assets/images/mobile/btn_two.png') no-repeat
          center center;
        background-size: 100%;
      }
      .btn-two-active {
        background: url('#{$cdnUrl}/assets/images/mobile/btn_two_active.png')
          no-repeat;
        background-size: 100%;
      }
      .btn-three {
        background: url('#{$cdnUrl}/assets/images/mobile/btn_three.png')
          no-repeat center center;
        background-size: 100%;
      }
      .btn-three-active {
        background: url('#{$cdnUrl}/assets/images/mobile/btn_three_active.png')
          no-repeat;
        background-size: 100%;
      }
      .btn-four {
        background: url('#{$cdnUrl}/assets/images/mobile/btn_four.png')
          no-repeat center center;
        background-size: 100%;
      }
      .btn-four-active {
        background: url('#{$cdnUrl}/assets/images/mobile/btn_four_active.png')
          no-repeat;
        background-size: 100%;
      }
      .btn-five {
        background: url('#{$cdnUrl}/assets/images/mobile/btn_five.png')
          no-repeat center center;
        background-size: 100%;
      }
      .btn-five-active {
        background: url('#{$cdnUrl}/assets/images/mobile/btn_five_active.png')
          center center no-repeat;
        background-size: 100%;
      }
    }
  }
  .part-three {
    background: url('#{$cdnUrl}/assets/images/mobile/part-three-bg.png')
      no-repeat;
    background-size: cover;
    padding-top: 0.22rem;
    position: relative;
    width: 100%;
    height: 8.245rem;
    .title-three {
      background: url('#{$cdnUrl}/assets/images/mobile/img_bs_title.png')
        no-repeat;
      background-size: 100%;
    }
    .myNewsSwiper {
      width: 2.975rem;
      height: 1.7rem;
      margin-top: 0.25rem;
    }
    .swiper-pagination {
      display: flex;
      right: 0.36rem;
      margin-top: 0.125rem;
    }
    .btn {
      width: 0.07rem;
      height: 0.07rem;
      background-color: #fff;
      margin: 0 0.02rem;
    }
    .btn-active {
      width: 0.4rem;
      background-color: rgb(219, 100, 255) !important;
    }
    .swiper-button-next {
      width: 0.27rem;
      height: 0.355rem;
      background: url('#{$cdnUrl}/assets/images/mobile/btn_bs_lb_right.png')
        no-repeat;
      background-size: 100%;
      top: 1.85rem;
      right: 0.08rem !important;
    }
    .swiper-button-prev {
      width: 0.27rem;
      height: 0.355rem;
      top: 1.85rem;
      background: url('#{$cdnUrl}/assets/images/mobile/btn_bs_lb_left.png')
        no-repeat;
      background-size: 100%;
      left: 0.08rem !important;
    }
    .new-list {
      padding: 0.15rem;
      margin-top: 0.1rem;
      .new-list-tab {
        display: flex;
        flex-wrap: nowrap;
        color: #fff;
        font-size: 0.14rem;
        font-weight: bold;
        .tab-items {
          flex-shrink: 0;
          text-align: center;
          padding: 0 0.17rem;
          position: relative;
          transition: all 0.2s linear;
          line-height: 0.3rem;
          &::after {
            transition: all 0.2s linear;
            transform: translateX(-50%) scaleX(0);
            content: '';
            width: 80%;
            position: absolute;
            left: 50%;
            bottom: 0rem;
            border-bottom: 0.03rem solid rgb(219, 100, 255);
          }
          &.tabactive {
            color: #db64ff;
            &::after {
              transition: all 0.2s linear;
              transform: translateX(-50%) scaleX(1);
              content: '';
              width: 80%;
              position: absolute;
              left: 50%;
              bottom: 0rem;
              border-bottom: 0.03rem solid rgb(219, 100, 255);
            }
          }
        }
      }
      .list-item {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        color: #fff;
        font-size: 0.12rem;
        border-bottom: 2px solid rgb(219, 100, 255);
        padding: 0.25rem 0 0.07rem 0;
        label {
          color: rgb(219, 100, 255);
          font-weight: bolder;
          padding-right: 0.05rem;
        }
        .list-item-left {
          display: flex;
          flex-wrap: nowrap;
          width: 80%;
          position: relative;
        }
        .text {
          width: 68%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          display: inline-block;
        }
        .time {
          color: rgb(186, 163, 199);
        }
      }
      .lists {
        margin-bottom: 0.25rem;
        height: 2.4rem;
      }
      .more {
        width: 1.4rem;
        height: 0.36rem;
        background: url('#{$cdnUrl}/assets/images/mobile/btn_bs_more.png')
          no-repeat;
        background-size: 100%;
        cursor: pointer;
        margin: 0 auto;
      }
      .item-icon {
        position: absolute;
        right: -0.12rem;
        width: 0.5rem;
        height: 0.3rem;
      }
      .item-new {
        background: url('#{$cdnUrl}/assets/images/pc/new.gif') no-repeat;
        background-size: 70%;
      }
      .item-hot {
        background: url('#{$cdnUrl}/assets/images/pc/hot.gif') no-repeat;
        background-size: 70%;
      }
    }
  }
  .center {
    display: flex;
    justify-content: center;
    margin-top: 0.45rem;
    margin-bottom: 0.7rem;
    .center-item {
      width: 0.63rem;
      height: 0.78rem;
      margin: 0 0.205rem;
    }
    .center-facebook {
      background: url('#{$cdnUrl}/assets/images/mobile/bottom-facebook.png')
        no-repeat;
      background-size: 100%;
    }
    .center-youtube {
      background: url('#{$cdnUrl}/assets/images/mobile/bottom-youtube.png')
        no-repeat;
      background-size: 100%;
    }
    .center-tiktok {
      background: url('#{$cdnUrl}/assets/images/mobile/bottom-tiktok.png')
        no-repeat;
      background-size: 100%;
    }
  }
  .bottom {
    color: #fff;
    font-size: 0.12rem;
    text-align: center;
    padding: 0.6rem 0;
    background-color: #000;
    position: relative;
    .link {
      padding: 0 0.3rem;
      cursor: pointer;
    }
  }
  .mobile-style {
    bottom: 0.42rem;
  }
}
</style>
