<template>
  <div :class="[ispc ? 'pc-bottom' : 'mobile-bottom', 'bottom']">
    <router-link
      class="link"
      to="/agreements?name=terms"
      >TERMS OF SERVICE</router-link
    >
    <router-link
      class="link"
      to="/agreements?name=privacy"
      >PRIVACY POLICY</router-link
    >
  </div>
</template>

<script>
export default {
  props: {
    ispc: {
      type: Boolean,
      default: true,
    },
  },
  name: 'Footer',
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.bottom {
  color: #fff;
  text-align: center;
  background-color: #000;
  position: relative;
  .link {
    cursor: pointer;
  }
}
.pc-bottom {
  font-size: 24px;
  padding: 120px 0;
  .link {
    padding: 0 30px;
  }
}
.mobile-bottom {
  font-size: 0.12rem;
  padding: 0.6rem 0;
  .link {
    padding: 0 0.3rem;
    cursor: pointer;
  }
}
</style>
