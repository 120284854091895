<template>
  <div class="info">
    <div class="top_bg"></div>
    <div class="top_logo"></div>
    <div class="title"></div>
    <div
      class="back"
      @click="handelClick">
      &lt; &lt; BACK HOME
    </div>
    <div class="main">
      <div class="main-top">
        <div class="name">{{ infoList.name }}</div>
        <span class="time">{{ infoList.published_at }}</span>
      </div>
      <div v-html="infoList.content"></div>
    </div>
    <div class="bottom_bg"></div>
    <back-to-top class="to-top"></back-to-top>
    <index-footer> </index-footer>
  </div>
</template>

<script>
import { getArticleInfo } from '@/api/index';
import BackToTop from '@/components/BackToTop/index';
import IndexFooter from '@/components/Footer/index';

export default {
  name: 'InfoPc',
  components: {
    BackToTop,
    IndexFooter,
  },
  data() {
    return {
      infoList: {},
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      getArticleInfo({
        id: this.$route.query.id,
      }).then((res) => {
        this.infoList = res.data;
      });
    },
    handelClick() {
      this.$router.push('/home');
    },
  },
};
</script>

<style lang="scss" scoped>
.info {
  position: relative;
  background: url('#{$cdnUrl}/assets/images/pc/bg_bs_new_bg.png') no-repeat;
  background-size: cover;
  overflow: hidden;
  width: 100%;
  .top_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 382px;
    height: 439px;
    background: url('#{$cdnUrl}/assets/images/pc/img_bs_sq.png') no-repeat;
    background-size: 100%;
  }
  .bottom_bg {
    position: absolute;
    bottom: 270px;
    right: 0;
    height: 425px;
    width: 462px;
    background: url('#{$cdnUrl}/assets/images/pc/img_bs_xq.png') no-repeat;
    background-size: 100%;
  }
  .top_logo {
    position: absolute;
    right: 50px;
    top: 110px;
    width: 916px;
    height: 100px;
    background: url('#{$cdnUrl}/assets/images/pc/img_bs.png') no-repeat;
    background-size: 100%;
  }
  .title {
    height: 89px;
    width: 321px;
    background: url('#{$cdnUrl}/assets/images/pc/part-three-title.png') no-repeat;
    background-size: 100%;
    margin: 50px 0 0 25px;
  }
  .back {
    position: absolute;
    top: 145px;
    right: 19%;
    color: #fff;
    font-weight: bolder;
    font-size: 50px;
    cursor: pointer;
  }
  .main {
    padding: 50px;
    width: 62%;
    margin: 70px auto;
    background: url('#{$cdnUrl}/assets/images/pc/center_bg.png') repeat;
    background-size: cover;
    color: #fff;
    min-height: 2000px;
    position: relative;
    .main-top {
      padding: 32px 0;
      border-bottom: 2px solid #c52aff;
      margin-bottom: 32px;
      text-align: center;
    }
    .name {
      font-size: 60px;
      margin-bottom: 32px;
    }
    .time {
      font-size: 30px;
    }
  }
  .to-top {
    right: 30px;
    bottom: 300px;
    z-index: 999;
  }
}
</style>
