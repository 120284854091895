<template>
  <div class="newslist">
    <download-top></download-top>

    <back-to-top
      :ispc="false"
      class="to-top"></back-to-top>
    <div class="new-list-tab">
      <div
        v-for="(item, index) in tabList"
        :key="index"
        class="tab-items"
        :class="tabIndex === index ? 'tabactive' : ''"
        @click="tabClick(index)">
        {{ item.name }}
      </div>
    </div>
    <div class="main">
      <div class="new-list">
        <div class="lists">
          <div
            class="list-item"
            v-for="(item, index) in newslist"
            :key="index"
            @click="handleItem(item)">
            <img
              :src="item.pic"
              class="item-img" />
            <div class="list-item-right">
              <span class="text">
                <label>[{{ tabList[tabIndex].name }}]</label>
                {{ item.name }}
              </span>
              <div class="time">{{ item.published_at }}</div>
            </div>
          </div>
        </div>
        <div
          class="more"
          v-if="showMore"
          @click="handleMore"></div>
      </div>
    </div>
    <index-footer :ispc="false"></index-footer>
  </div>
</template>

<script>
import BackToTop from '@/components/BackToTop/index';
import downloadTop from '@/components/download-top/index';
import IndexFooter from '@/components/Footer/index';
import { getArticleLists } from '@/api/index';

export default {
  name: 'Newslist',
  data() {
    return {
      tabList: [
        { name: 'LATEST' },
        {
          name: 'NEWS',
        },
        {
          name: 'ACTIVITY',
        },
        {
          name: 'NOTICE',
        },
      ],
      newslist: [],
      tabIndex: 0,
      page: 1,
      showMore: false,
    };
  },
  components: {
    BackToTop,
    downloadTop,
    IndexFooter,
  },
  mounted() {
    this.getArticleLists(this.tabIndex, this.page);
  },
  methods: {
    tabClick(index) {
      this.tabIndex = index;
      this.page = 1;
      this.newslist = [];
      this.getArticleLists(index, this.page);
    },
    handleItem(item) {
      this.$router.push({
        path: '/info',
        query: {
          id: item.id,
        },
      });
    },
    getArticleLists(type, page) {
      getArticleLists({
        game_id: 1,
        type: type,
        page: page,
        limit: 6,
      }).then((res) => {
        this.newslist = [...this.newslist, ...res.data.list];
        if (this.newslist.length <= 5 || res.data.list.length === 0) {
          this.showMore = false;
        } else {
          this.showMore = true;
        }
      });
    },
    handleMore() {
      this.page++;
      this.getArticleLists(this.tabIndex, this.page);
    },
  },
};
</script>

<style lang="scss" scoped>
.newslist {
  position: relative;
  width: 100%;
 background: url('#{$cdnUrl}/assets/images/mobile/bgban.png') no-repeat;
  background-size: cover;
  overflow: hidden;
  .new-list-tab {
    display: flex;
    flex-wrap: nowrap;
    color: #fff;
    font-size: 0.14rem;
    font-weight: bold;
    margin-top: 0.8rem;
    .tab-items {
      flex-shrink: 0;
      text-align: center;
      padding: 0 0.2rem;
      position: relative;
      transition: all 0.2s linear;
      line-height: 0.4rem;
      &::after {
        transition: all 0.2s linear;
        transform: translateX(-50%) scaleX(0);
        content: '';
        width: 80%;
        position: absolute;
        left: 50%;
        bottom: 0rem;
        border-bottom: 0.03rem solid rgb(219, 100, 255);
      }
      &.tabactive {
        color: #db64ff;
        &::after {
          transition: all 0.2s linear;
          transform: translateX(-50%) scaleX(1);
          content: '';
          width: 80%;
          position: absolute;
          left: 50%;
          bottom: 0rem;
          border-bottom: 0.03rem solid rgb(219, 100, 255);
        }
      }
    }
  }
  .main {
    width: 100%;
   background: url('#{$cdnUrl}/assets/images/mobile/img_mainban.png') repeat-y;

    .new-list {
      padding: 0 0.15rem;
      .list-item {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        color: #fff;
        font-size: 0.12rem;
        border-bottom: 2px solid rgb(219, 100, 255);
        padding: 0.15rem 0;
        .item-img {
          width: 1.1rem;
          height: 0.7rem;
          cursor: pointer;
        }
        label {
          color: rgb(219, 100, 255);
          font-weight: bolder;
          padding-right: 0.05rem;
        }
        .list-item-right {
          width: 2.25rem;
          margin-left: 0.15rem;
        }
        .text {
          width: 99%;
          display: block;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          line-height: 0.15rem;
          max-height: 0.3rem;
        }
        .time {
          color: #baa3c7;
          margin-top: 0.25rem;
        }
      }
      .lists {
        min-height: calc(100vh - 2.5rem);
      }
      .more {
        width: 0.54rem;
        height: 0.275rem;
       background: url('#{$cdnUrl}/assets/images/mobile/more.png') no-repeat;
        background-size: 100%;
        cursor: pointer;
        margin: 0 auto;
        padding: 0.1rem 0 0.5rem 0;
      }
    }
  }
}
</style>
