<template>
  <div class="download">
    <div class="text">
      <img
        :src="`${imageCdnUrl}/assets/images/mobile/img_bs_head_icon.png`"
        alt=""
        class="img"
        @click="handelClick" />
      <div>
        <p>BURST SPACE</p>
        <span>Pixel shooting game</span>
      </div>
    </div>
    <div
      class="download-btn"
      @click="handelDownload"></div>
  </div>
</template>

<script>
export default {
  name: 'download',
  data() {
    return {};
  },
  methods: {
    handelClick() {
      this.$router.push('/home');
    },
    handelDownload() {
      // var u = navigator.userAgent;
      // var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
      // // var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      // if (isAndroid) {
      //   window.open(
      //     'https://play.google.com/store/apps/details?id=cc.k2games.burstspace'
      //   );
      // }
      this.$router.push('/order');
    },
  },
};
</script>

<style lang="scss" scoped>
.download {
  width: 100%;
  height: 0.8rem;
  background: url('#{$cdnUrl}/assets/images/mobile/img_bs_head.png') no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  .img {
    width: 0.6rem;
    height: 0.6rem;
    margin: 0 0.16rem 0 0.1rem;
  }
  .text {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    p {
      font-size: 0.18rem;
    }
    span {
      font-size: 0.12rem;
    }
  }
  .download-btn {
    width: 1.41rem;
    height: 0.41rem;
    background: url('#{$cdnUrl}/assets/images/mobile/pre-register.png')
      no-repeat;
    background-size: cover;
    margin: 0 0.225rem;
  }
}
</style>
