var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-mobile"},[_c('download-top'),_c('div',{staticClass:"part part-one"},[_c('div',{staticClass:"logo"}),_c('div',{staticClass:"play-video",on:{"click":_vm.playVedio}},[_c('div',{staticClass:"play-circle animate"}),_c('div',{staticClass:"play-btn"})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShow),expression:"isShow"}],staticClass:"play-bg"}),_c('video-player',{ref:"videoPlayer",attrs:{"isShow":_vm.isShow,"ispc":false,"width":'3.2rem',"height":'1.8rem'},on:{"close":_vm.closePlayer}}),_c('div',{staticClass:"link"},_vm._l((_vm.channelList),function(item,index){return _c('div',{key:index,class:['link-btn', item.name],on:{"click":function($event){return _vm.handleChannel(item)}}})}),0)],1),_c('div',{staticClass:"part part-two",on:{"mouseleave":function($event){return _vm.mouseenter(_vm.activeIndex)}}},[_c('div',{staticClass:"title title-two"}),_c('swiper',{ref:"mySwiper",attrs:{"option":_vm.swiperOption,"circular":"true","loop":"true"}},_vm._l((_vm.bannerlist),function(item,index){return _c('swiper-slide',{key:index},[_c('img',{class:['banner-img', `banner-img-${item.name}`],attrs:{"src":`${_vm.imageCdnUrl}${item.imgSrc}`}}),_c('div',{staticClass:"info-box"},[_c('div',{staticClass:"info-name"},[_c('div',{class:`info-name-${item.name}`}),_c('div',{class:['gif', `gif-${item.name}`]})]),_c('div',{staticClass:"line"}),_c('span',[_vm._v(_vm._s(item.info))])])])}),1),_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"},_vm._l((_vm.bannerlist),function(item,index){return _c('div',{key:index,class:[
          _vm.activeIndex === index
            ? `btn-${item.name}-active`
            : `btn-${item.name}`,
          'btn',
        ],on:{"click":function($event){return _vm.mouseenter(index)},"touchend":function($event){return _vm.mouseleave()}}})}),0)],1),_c('div',{staticClass:"part-three"},[_c('div',{staticClass:"title title-three"}),_c('swiper',{ref:"myNewsSwiper",staticClass:"myNewsSwiper",attrs:{"option":_vm.newsSwiperOption}},_vm._l((_vm.newsBanner),function(item,index){return _c('swiper-slide',{key:index,staticClass:"new-item"},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":item.pic},on:{"click":function($event){return _vm.handelChangeUrl(item)}}})])}),1),_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},on:{"click":_vm.swipePrev,"touchend":function($event){return _vm.newsMouseleave()}},slot:"button-prev"}),_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},on:{"click":_vm.swipeNext,"touchend":function($event){return _vm.newsMouseleave()}},slot:"button-next"}),_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"},_vm._l((_vm.newsBanner),function(item,index){return _c('div',{key:index,class:[_vm.newsActive === index ? `btn-active` : '', 'btn'],on:{"click":function($event){return _vm.newsMouseenter(index)},"touchend":function($event){return _vm.newsMouseleave(index)}}})}),0),_c('div',{staticClass:"new-list"},[_c('div',{staticClass:"new-list-tab"},_vm._l((_vm.tabList),function(item,index){return _c('div',{key:index,staticClass:"tab-items",class:_vm.tabIndex === index ? 'tabactive' : '',on:{"click":function($event){return _vm.tabClick(index)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0),_c('div',{staticClass:"lists"},_vm._l((_vm.newslist),function(item,index){return _c('div',{key:index,staticClass:"list-item",on:{"click":function($event){return _vm.handleItem(item)}}},[_c('div',{staticClass:"list-item-left"},[_c('label',[_vm._v("["+_vm._s(_vm.tabList[_vm.tabIndex].name)+"]")]),_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{class:[
                'item-icon',
                item.flag == 1
                  ? 'item-hot'
                  : item.flag == 2
                  ? 'item-new'
                  : '',
              ]})]),_c('div',{staticClass:"time"},[_vm._v(_vm._s(item.published_at))])])}),0),_c('div',{staticClass:"more",on:{"click":_vm.handleMore}}),_c('div',{staticClass:"center"},_vm._l((_vm.linklist),function(item,index){return _c('div',{key:index,class:['center-item', `center-${item.name}`],on:{"click":function($event){return _vm.handleClick(item)}}})}),0),_c('back-to-top',{attrs:{"ispc":false}})],1)],1),_c('index-footer',{attrs:{"ispc":false}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }