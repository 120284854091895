var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"order"},[_c('swiper',{ref:"orderSwiper",staticClass:"stop-swiping",attrs:{"id":"orderSwiper","options":_vm.swiperOption}},[_c('swiper-slide',{staticClass:"first-swiper animate__animated animate__fadeIn"},[_c('div',{staticClass:"logo"}),_c('div',{staticClass:"website-btn",on:{"click":_vm.handleHome}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.firstShow),expression:"firstShow"}],staticClass:"logo-main animate__animated animate__fadeInLeft"},[_c('div',{staticClass:"logo-place"}),_c('div',{staticClass:"btn-google",on:{"click":function($event){return _vm.handleClickPlay('register1_button_point')}}})])]),_c('swiper-slide',{staticClass:"two-swiper"},[_c('div',{staticClass:"bg-right-top"}),_c('div',{staticClass:"bg-left-bottom"}),_c('div',{staticClass:"two-swiper-main"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.twoShow),expression:"twoShow"}],staticClass:"swiper-main-bg animate__animated animate__fadeInRight"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.twoShow),expression:"twoShow"}],class:[
            'card-lists',
            'animate__animated',
            'animate__fadeInUp',
            `card-lists-active-${_vm.activeIndexs}`,
          ],attrs:{"id":"cardLists"}},[_c('ul',{},_vm._l((_vm.cardLists),function(item,index){return _c('li',{key:index,class:[
                item.activeIndex === 1
                  ? `${item.name}-active shadow-color${index + 1}`
                  : `${item.name}`,
                'card-item',
              ]},[_c('div',{staticClass:"item-img"}),_c('div',{staticClass:"animate-border"},[_c('i'),_c('i')])])}),0),_c('div',{staticClass:"btn-google",on:{"click":function($event){return _vm.handleClickPlay('register2_button_point')}}})])])]),_c('swiper-slide',{staticClass:"three-swiper"},[_c('div',{staticClass:"three-swiper-main"},[_c('swiper',{ref:"swiperBanner",attrs:{"options":_vm.swiperBannerOption}},_vm._l((_vm.swiperBannerLists),function(item,index){return _c('swiper-slide',{key:index,staticClass:"new-item"},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":`${_vm.imageCdnUrl}${item.pic}`}})])}),1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.threeShow),expression:"threeShow"}],staticClass:"swiper-button-prev animate__animated animate__fadeInLeft",attrs:{"slot":"button-prev"},on:{"click":_vm.swiperPrev},slot:"button-prev"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.threeShow),expression:"threeShow"}],staticClass:"swiper-button-next animate__animated animate__fadeInRight",attrs:{"slot":"button-next"},on:{"click":_vm.swiperNext},slot:"button-next"})],1),_c('div',{staticClass:"toTop animate__animated animate__fadeInUp",on:{"click":_vm.handleTop}})])],1),_c('div',{staticClass:"toogle-item"},_vm._l((_vm.linklist),function(item,index){return _c('div',{key:index,class:`${item.name}`,on:{"click":function($event){return _vm.handleClick(item)}}})}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }