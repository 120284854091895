var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"order"},[_c('div',{staticClass:"part-one"},[_c('div',{staticClass:"part-one-top"},[_c('div',{staticClass:"logo"}),_c('div',{staticClass:"website-btn",on:{"click":_vm.handleHome}})]),_c('img',{staticClass:"logo-place",attrs:{"src":`${_vm.imageCdnUrl}/assets/images/mobile/place.png`}}),_c('img',{staticClass:"logo-text",attrs:{"src":`${_vm.imageCdnUrl}/assets/images/mobile/logo-text.png`}}),_c('img',{staticClass:"google-btn",attrs:{"src":`${_vm.imageCdnUrl}/assets/images/mobile/google-btn.png`},on:{"click":function($event){return _vm.handleClickPlay('register1_button_point')}}})]),_c('div',{staticClass:"part-two"},[_c('div',{staticClass:"two-main-text"}),_vm._m(0),_c('ul',{class:['card-lists', `card-lists-active-${_vm.activeIndexs}`]},_vm._l((_vm.cardLists),function(item,index){return _c('li',{key:index,class:[
          item.activeIndex === 1
            ? `${item.name}-active shadow-color${index + 1}`
            : `${item.name}`,
          'card-item',
        ]},[_c('div',{staticClass:"item-img"}),_vm._m(1,true)])}),0),_c('div',{staticClass:"swiper-part"},[_c('swiper',{ref:"mobileSwiper",attrs:{"options":_vm.swiperBannerOption}},_vm._l((_vm.swiperBannerLists),function(item,index){return _c('swiper-slide',{key:index,staticClass:"new-item"},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":`${_vm.imageCdnUrl}${item.pic}`}})])}),1),_c('div',{staticClass:"swiper-button-next animate__animated animate__fadeInRight",attrs:{"slot":"button-next"},on:{"click":_vm.swiperNext},slot:"button-next"})],1)]),_c('div',{staticClass:"footer"},[_vm._l((_vm.linklist),function(item,index){return _c('div',{key:index,class:['link-item', `${item.name}`],on:{"click":function($event){return _vm.handleClick(item)}}})}),_c('back-to-top',{attrs:{"ispc":false}})],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"two-main-bg"},[_c('div',{staticClass:"down"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"animate-border"},[_c('i'),_c('i')])
}]

export { render, staticRenderFns }