<template>
  <div class="order">
    <div class="part-one">
      <div class="part-one-top">
        <div class="logo"></div>
        <div
          class="website-btn"
          @click="handleHome"></div>
      </div>
      <img
        :src="`${imageCdnUrl}/assets/images/mobile/place.png`"
        class="logo-place" />
      <img
        :src="`${imageCdnUrl}/assets/images/mobile/logo-text.png`"
        class="logo-text" />
      <img
        :src="`${imageCdnUrl}/assets/images/mobile/google-btn.png`"
        class="google-btn"
        @click="handleClickPlay('register1_button_point')" />
    </div>
    <div class="part-two">
      <div class="two-main-text"></div>
      <div class="two-main-bg">
        <div class="down"></div>
      </div>
      <ul :class="['card-lists', `card-lists-active-${activeIndexs}`]">
        <li
          v-for="(item, index) in cardLists"
          :key="index"
          :class="[
            item.activeIndex === 1
              ? `${item.name}-active shadow-color${index + 1}`
              : `${item.name}`,
            'card-item',
          ]">
          <div class="item-img"></div>
          <div class="animate-border">
            <i></i>
            <i></i>
          </div>
        </li>
      </ul>
      <div class="swiper-part">
        <swiper
          :options="swiperBannerOption"
          ref="mobileSwiper">
          <swiper-slide
            class="new-item"
            v-for="(item, index) in swiperBannerLists"
            :key="index">
            <img
              :src="`${imageCdnUrl}${item.pic}`"
              style="width: 100%; height: 100%" />
          </swiper-slide>
        </swiper>
        <div
          class="swiper-button-next animate__animated animate__fadeInRight"
          slot="button-next"
          @click="swiperNext"></div>
      </div>
    </div>

    <div class="footer">
      <div
        v-for="(item, index) in linklist"
        :key="index"
        :class="['link-item', `${item.name}`]"
        @click="handleClick(item)"></div>
      <back-to-top :ispc="false"></back-to-top>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import BackToTop from '@/components/BackToTop/index';
import { getBtnlog } from '@/api/index';

export default {
  name: 'Order',
  data() {
    return {
      cardLists: [
        {
          name: 'nugget',
          activeIndex: 1, // 解锁
        },
        {
          name: 'orb',
          activeIndex: 1, // 未解锁
        },
        {
          name: 'coin',
          activeIndex: 1,
        },
        {
          name: 'booster',
          activeIndex: 1,
        },
        {
          name: 'coin2',
          activeIndex: 1,
        },
        {
          name: 'alfred',
          activeIndex: 1,
        },
      ],
      swiperBannerOption: {
        allowTouchMove: false,
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
      },
      swiperBannerLists: [
        { pic: '/assets/images/mobile/swiper-one.png' },
        { pic: '/assets/images/mobile/swiper-two.png' },
        { pic: '/assets/images/mobile/swiper-three.png' },
        { pic: '/assets/images/mobile/swiper-four.png' },
        { pic: '/assets/images/mobile/swiper-five.png' },
      ],
      linklist: [
        {
          name: 'googleplay',
          url: 'https://play.google.com/store/apps/details?id=cc.k2games.burstspace',
          type: 'google_button_point',
        },
        {
          name: 'tiktok',
          url: 'https://www.tiktok.com/@burstspace',
          type: 'tiktok_button_point',
        },
        {
          name: 'facebook',
          url: 'https://www.facebook.com/burstspacegroup',
          type: 'facebook_button_point',
        },
        {
          name: 'youtube',
          url: 'https://www.youtube.com/@burstspaceglobal',
          type: 'youtube_button_point',
        },
      ],
      activeIndexs: 'six',
    };
  },
  components: {
    swiper,
    swiperSlide,
    BackToTop,
  },
  computed: {
    mobileSwiper() {
      return this.$refs.mobileSwiper.swiper;
    },
  },
  methods: {
    swiperNext() {
      this.mobileSwiper.slideNext();
    },
    handelBtnlog(type) {
      getBtnlog({
        game_id: 1,
        event: type,
      }).then((res) => {
        if (res.code === 200) {
          console.log('记录成功！');
        } else {
          console.log('记录失败！');
        }
      });
    },
    handleClickPlay(type) {
      this.handelBtnlog(type);
      window.open(
        'https://play.google.com/store/apps/details?id=cc.k2games.burstspace'
      );
    },
    handleClick(item) {
      this.handelBtnlog(item.type);
      if (item.url !== '') {
        window.open(item.url);
      }
    },
    handleHome() {
      this.$router.push('/home');
    },
  },
  mounted() {
    this.handelBtnlog('visitor_page_view');
  },
};
</script>

<style lang="scss" scoped>
$activeIndex: 'six';
$channels: 'googleplay', 'facebook', 'tiktok', 'youtube';
$items: (
  nugget: #fff,
  orb: #6dffdb,
  coin: #8cc8ff,
  booster: #e867ff,
  coin2: #ffb56e,
  alfred: #fff66b,
);
$rgbs: rgba(255, 255, 255, 0.2), rgba(36, 233, 184, 0.2),
  rgba(52, 130, 248, 0.2), rgba(203, 36, 233, 0.2), rgba(233, 133, 36, 0.2),
  rgba(248, 236, 52, 0.2);

.order {
  width: 100%;
  height: 100vh;
  // overflow: hidden;
  .part-one {
    width: 100%;
    height: 6.67rem;
    background: url('#{$cdnUrl}/assets/images/mobile/first-bg.jpg') no-repeat
      bottom center;
    background-size: 100%;
    padding-top: 0.24rem;
    text-align: center;
    .part-one-top {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
    }
    .logo {
      width: 0.74rem;
      height: 0.52rem;
      background: url('#{$cdnUrl}/assets/images/mobile/logo.png') no-repeat;
      background-size: 100%;
      margin: 0 0 0.14rem 0.27rem;
    }
    .website-btn {
      width: 1.01rem;
      height: 0.46rem;
      background: url('#{$cdnUrl}/assets/images/mobile/website-btn.png')
        no-repeat;
      background-size: 100%;
      margin: -0.08rem 0.24rem 0 0;
    }
    .logo-place {
      width: 1.69rem;
      height: 0.25rem;
    }
    .logo-text {
      width: 3.36rem;
      height: 0.6rem;
      margin: 0.11rem 0 0.09rem 0;
    }
    .google-btn {
      width: 1.62rem;
      height: 0.42rem;
    }
  }
  .part-two {
    width: 100%;
    background: url('#{$cdnUrl}/assets/images/mobile/two-bg.jpg') no-repeat;
    background-size: 100%;
    padding-top: 0.36rem;
    height: 6.655rem;
    .two-main-text {
      width: 3.3rem;
      height: 0.41rem;
      background: url('#{$cdnUrl}/assets/images/mobile/two-main-text.png')
        no-repeat;
      background-size: 100%;
      margin: 0 auto;
    }
    .two-main-bg {
      width: 3.08rem;
      height: 2.58rem;
      background: url('#{$cdnUrl}/assets/images/mobile/two-main-bg.png')
        no-repeat;
      background-size: 100%;
      margin: -0.1rem auto 0 auto;
      position: relative;
      .down {
        background: url('#{$cdnUrl}/assets/images/mobile/down.png') no-repeat;
        background-size: 100%;
        position: absolute;
        left: -0.1rem;
        width: 0.21rem;
        height: 0.36rem;
        bottom: 0.7rem;
      }
    }
    .card-lists {
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      align-items: flex-end;
      padding-bottom: 0.48rem;
      justify-content: center;
      margin: -0.55rem 0 0.1rem 0;
    }
    .card-lists-active-#{$activeIndex} {
      background: url('#{$cdnUrl}/assets/images/mobile/items-#{$activeIndex}.png')
        no-repeat bottom center;
      background-size: 100%;
    }
    .card-item {
      overflow: hidden;
      margin: 0 0.035rem;
      position: relative;
      width: 0.473rem;
      height: 0.6rem;
      border-radius: 10px 0 0 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .shadow-color1 {
      margin-left: -0.02rem;
    }
    .coin,
    .coin-active,
    .booster,
    .booster-active {
      width: 0.462rem;
    }
    .alfred,
    .alfred-active {
      width: 0.63rem;
      height: 0.8rem;
    }
    @each $i, $c in $items {
      .#{$i} {
        .item-img {
          background: url('#{$cdnUrl}/assets/images/pc/#{$i}.png')
            no-repeat
            bottom
            center;
          background-size: 100%;
          width: 100%;
          height: 100%;
        }
      }
      .#{$i}-active {
        .item-img {
          width: 91%;
          height: 94%;
          background: url('#{$cdnUrl}/assets/images/pc/#{$i}-active.png')
            no-repeat;
          background-size: 100%;
          margin-top: 0.015rem;
        }
        .animate-border {
          position: absolute;
          top: 0px;
          width: 100%;
          height: 100%;
          &::before,
          &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 4px;
            z-index: 999;
          }
          i {
            position: absolute;
            display: inline-block;
            height: 100%;
            width: 4px;
            z-index: 999;
          }
          &::before {
            top: -0px;
            left: -100%;
            background-image: linear-gradient(
              90deg,
              transparent,
              #{$c},
              transparent
            );
            animation: left 4s linear infinite;
          }
          i:nth-child(1) {
            top: -100%;
            right: -0px;
            background-image: linear-gradient(
              180deg,
              transparent,
              #{$c},
              transparent
            );
            /* 动画名称  动画持续时间  动画渲染函数 动画延迟时间 动画执行次数 */
            animation: top 4s linear 1s infinite;
          }
          &::after {
            bottom: -0px;
            right: -100%;
            background-image: linear-gradient(
              -90deg,
              transparent,
              #{$c},
              transparent
            );
            animation: right 4s linear 2s infinite;
          }
          i:nth-child(2) {
            bottom: -100%;
            left: -0px;
            background-image: linear-gradient(
              180deg,
              transparent,
              #{$c},
              transparent
            );
            // linear-gradient(135deg, transparent 5px, #f8ec34 0);
            animation: bottom 4s linear 3s infinite;
          }
        }
      }
    }
    @for $i from 1 through 6 {
      .shadow-color#{$i} {
        background-color: nth($rgbs, $i);
      }
    }
  }
  .swiper-part {
    width: 100%;
    padding-bottom: 0.02rem;
    // background: url('#{$cdnUrl}/images/mobile/three-bg.jpg') no-repeat
    //   bottom center;
    position: relative;
    .swiper-container {
      height: 2.53rem;
      width: 100%;
    }
    .swiper-button-next {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0%, -50%);
      background: url('#{$cdnUrl}/assets/images/mobile/arr-right.png') no-repeat;
      background-size: 100%;
      width: 0.36rem;
      height: 0.3rem;
    }
  }
  .footer {
    width: 100%;
    background: url('#{$cdnUrl}/assets/images/mobile/footer.jpg') no-repeat;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0.135rem 0;
    position: relative;
    .link-item {
      width: 0.97rem;
      height: 0.3rem;
      margin: 0.035rem 0.3rem 0.035rem 0.3rem;
    }
    @each $i in $channels {
      .#{$i} {
        background: url('#{$cdnUrl}/assets/images/mobile/#{$i}.png') no-repeat;
        background-size: 100%;
      }
    }
    .mobile-style {
      top: 50%;
      bottom: 50%;
      transform: translate(-50%, -50%);
    }
  }
  @keyframes left {
    0% {
      left: -100%;
    }
    50%,
    100% {
      left: 100%;
    }
  }
  @keyframes top {
    0% {
      top: -100%;
    }
    50%,
    100% {
      top: 100%;
    }
  }
  @keyframes right {
    0% {
      right: -100%;
    }
    50%,
    100% {
      right: 100%;
    }
  }
  @keyframes bottom {
    0% {
      bottom: -100%;
    }
    50%,
    100% {
      bottom: 100%;
    }
  }
}
</style>
