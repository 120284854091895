<template>
  <div class="agreements">
    <top-nav @change="navChange"></top-nav>
    <div class="main">
      <div class="silde-nav">
        <div
          :class="['nav-item', active === index ? 'active' : '']"
          v-for="(items, index) in navList"
          :key="index"
          @click="sildeChange(index)">
          {{ items.label }}
        </div>
      </div>
      <div class="center">
        <h3 class="title">{{ title }}</h3>
        <transition name="fade-transform">
          <div
            class="content"
            v-if="active === 0">
            <p>Effective Date: February 1, 2023</p>
            <p>
              The terms of this agreement ("Terms of Service") govern the
              relationship between you and K2’s games , (hereinafter "K2" or
              "Us" or "We") regarding your use of K2's games, store, sites and
              related services (the "Service"). Use of the Service is also
              governed by K2's Privacy Policy and other relevant policies, which
              are incorporated herein by reference. 
            </p>
            <p>
              Before accessing or using the Service, including browsing any K2
              website or accessing a game, you must agree to these Terms of
              Service and the Privacy Policy. A guest account may be created for
              you for the use of the Service, and you may also be required to
              register an account on the Service (collectively "Account"). These
              Accounts include, for example, game Accounts and K2 ID. By using
              or registering for an Account or otherwise using the Service, you
              affirm that you are the legal age of majority in your country of
              residence. If you are not, your legal guardian must review and
              agree to these Terms of Service.
            </p>
            <p>
              BY INSTALLING, USING OR OTHERWISE ACCESSING THE SERVICE, YOU AGREE
              TO THESE TERMS OF SERVICE. IF YOU DO NOT AGREE TO THESE TERMS OF
              SERVICE, PLEASE DO NOT INSTALL, USE OR OTHERWISE ACCESS THE
              SERVICE. USE OF THE SERVICE IS VOID WHERE PROHIBITED.
            </p>
            <p>
              IMPORTANT NOTICE: For U.S. and Canadian residents, you also agree
              that disputes with K2 must be resolved on an individual basis
              through final and binding arbitration as described in Section 8
              (“Dispute Resolution”).
            </p>
            <p>
              K2 reserves the right, at its discretion, to change, modify, add
              or remove portions of these Terms of Service, its Privacy
              Policy and other relevant K2 policies at any time by posting the
              amended terms on the Service. You will be deemed to have accepted
              such changes by continuing to use the Service. If at any point you
              do not agree to any portion of the then-current version of our
              Terms of Service, the K2 Privacy Policy, or any other K2 policy,
              rules or codes of conduct relating to your use of the Service,
              your right to use the Service shall immediately terminate, and you
              must immediately stop using the Service.
            </p>
            <h1>1. Using the Service</h1>
            <h3>1.1. Your Right to Use the Service</h3>
            <p>
              Subject to your agreement and continuing compliance with these
              Terms of Service and any other relevant K2 policies, you have a
              non-exclusive, non-transferable, non-sublicensable, revocable and
              limited right to access and use the Service for your own
              non-commercial entertainment purposes. You agree not to use the
              Service for any other purpose.
            </p>
            <p>The following restrictions apply to the use of the Service:</p>
            <p>
              * You accept full responsibility for any unauthorized use of the
              Service by minors. You are responsible for any use of your credit
              card or other payment instrument (such as PayPal) by minors.
            </p>
            <p>
              * You shall not (or attempt to) purchase, sell, rent or give away
              your Account, create an Account using a false identity or
              information, or on behalf of someone other than yourself; You
              shall not use the Service if you have previously been removed by
              K2, or previously been banned from playing any K2 game.
            </p>
            <p>
              * You shall not use the Service to advertise, or solicit, or
              transmit any commercial advertisements, including chain letters,
              junk, or spam e-mail or repetitive or misleading messages to
              anyone.
            </p>
            <h3>Login information and Your Account</h3>
            <p>
              You may be required to select a password for your Account, or you
              may also use other credentials to access the Account ("Login
              Information"). You shall not share the Account or the Login
              Information, nor let anyone else access your Account or do
              anything else that might jeopardize the security of your Account.
              In the event you become aware of or reasonably suspect any breach
              of security, including without limitation any loss, theft, or
              unauthorized disclosure of the Login Information, you must
              immediately notify K2 and modify your Login Information. You are
              solely responsible for maintaining the confidentiality of the
              Login Information, and you will be responsible for all uses of the
              Login Information, including purchases, whether or not authorized
              by you. You are responsible for anything that happens through your
              Account.
            </p>
            <p>
              K2 reserves the right to remove or reclaim any usernames at any
              time and for any reason, including but not limited to claims by a
              third party that a username violates the third party's rights.
            </p>
            <p>
              Unless you are using K2 ID, the Service supports only one Account
              per game on a supported device. 
            </p>
            <h3>Use Limitations</h3>
            <p>
              Any use of the Service in violation of these Use Limitations is
              strictly prohibited, can result in the immediate revocation of
              your limited right and may subject you to liability for violations
              of law.
            </p>
            <p>You agree that you will not, under any circumstances:</p>
            <p>
              * Engage in any act that K2 deems to be in conflict with the
              spirit or intent of the Service or make improper use of K2's
              support services.
            </p>
            <p>
              * Use or take part (directly or indirectly) in the use of cheats,
              exploits, automation software, emulators, bots, hacks, mods or any
              unauthorized third-party software designed to modify or interfere
              with the Service, any K2 game or any K2 game experience.
            </p>
            <p>
              * Modify or cause to be modified any files that are a part of the
              Service or any K2 game without K2's express written consent.
            </p>
            <p>
              * Disrupt, interfere with or otherwise adversely affect the normal
              flow of the Service or otherwise act in a manner that may
              negatively affect other users' experience when using the Service
              or playing K2's games. This includes win trading and any other
              kind of manipulation of rankings, taking advantage of errors in
              the Service to gain an unfair edge over other players and any
              other act that intentionally abuses or goes against the design of
              the Service.
            </p>
            <p>
              * Disrupt, overburden, or aid or assist in the disruption or
              overburdening of any computer or server used to offer or support
              the Service or any K2 game environment.
            </p>
            <p>
              * Institute, assist, or become involved in any type of attack,
              including without limitation distribution of a virus, denial of
              service attacks upon the Service, or other attempts to disrupt the
              Service or any other person's use or enjoyment of the Service.
            </p>
            <p>
              * Attempt to gain unauthorized access to the Service, Accounts
              registered or used by others or to the computers, servers, or
              networks connected to the Service by any means other than the user
              interface provided by K2, including but not limited to, by
              circumventing or modifying, attempting to circumvent or modify, or
              encouraging or assisting any other person to circumvent or modify,
              any security, technology, device, or software that is part of the
              Service.
            </p>
            <p>
              * Post any information that is abusive, threatening, obscene,
              defamatory, libelous, or racially, sexually, religiously, or
              otherwise objectionable or offensive or engage in ongoing toxic
              behavior, such as by repeatedly posting information on an
              unsolicited basis.
            </p>
            <p>
              * Post any information that contains nudity, excessive violence,
              or offensive subject matter or that contains a link to such
              content.
            </p>
            <p>
              * Attempt to, or harass, abuse, or harm, or advocate or incite
              harassment, abuse, or harm of another person, group, including K2
              employees, including K2's customer service representatives.
            </p>
            <p>
              * Make available through the Service any material or information
              that infringes any copyright, trademark, patent, trade secret,
              right of privacy, right of publicity, or other right of any person
              or entity or impersonates any other person, including without
              limitation a K2 employee.
            </p>
            <p>
              * Reverse engineer, decompile, disassemble, decipher or otherwise
              attempt to derive the source code for any underlying software or
              other intellectual property used to provide the Service or any K2
              game, or to obtain any information from the Service or any K2 game
              using any method not expressly permitted by K2.
            </p>
            <p>
              Solicit, or attempt to solicit, Login Information or any other
              login credentials or personal information from other users of the
              Service or any K2 game.
            </p>
            <p>
              * Collect or post anyone's private information, including
              personally identifiable information (whether in text, image or
              video form), identification documents, or financial information
              through the Service.
            </p>
            <p>
              * Use any K2 game for gambling, betting or any similar activity in
              which prizes or rewards can be won (directly or indirectly),
              including betting on the outcome of matches in which you
              participate as a player, irrespective of whether or not there is a
              fee or stake involved.
            </p>
            <p>
              * Use the Service in any way that would violate any export
              controls, anti-money laundering rules, economic sanctions or
              similar laws or regulations including but not limited to those
              imposed by the United States of America and/or the European Union.
            </p>
            <p>
              K2 reserves the right to determine what conduct it considers to be
              in violation of the rules of use or otherwise outside the intent
              or spirit of these Terms of Service or the Service itself. K2
              reserves the right to take action as a result, which may include
              terminating your Account and prohibiting you from using the
              Service in whole or in part.
            </p>
            <h3>1.2. Suspension and Termination of Account and Service</h3>
            <p>
              WITHOUT LIMITING ANY OTHER REMEDIES, K2 MAY LIMIT, SUSPEND,
              TERMINATE, MODIFY, OR DELETE ACCOUNTS OR ACCESS TO THE SERVICE OR
              PORTIONS THEREOF WITH OR WITHOUT NOTICE TO YOU (i) IF YOU ARE, OR
              K2 SUSPECTS THAT YOU ARE, FAILING TO COMPLY WITH THESE TERMS OF
              SERVICE; OR (ii) FOR ANY ACTUAL OR SUSPECTED ILLEGAL OR IMPROPER
              USE OF THE SERVICE. YOU CAN LOSE YOUR USERNAME AND PERSONA IN THE
              SERVICE AS A RESULT OF ACCOUNT TERMINATION OR LIMITATION, AS WELL
              AS ANY BENEFITS, PRIVILEGES, EARNED VIRTUAL ITEMS AND PURCHASED
              VIRTUAL ITEMS ASSOCIATED WITH YOUR USE OF THE SERVICE, AND K2 IS
              UNDER NO OBLIGATION TO COMPENSATE YOU FOR ANY SUCH LOSSES OR
              RESULTS.
            </p>
            <p>
              WITHOUT LIMITING OUR OTHER REMEDIES, WE MAY LIMIT, SUSPEND OR
              TERMINATE THE SERVICE AND ACCOUNTS OR PORTIONS THEREOF, PROHIBIT
              ACCESS TO OUR GAMES AND SITES, AND THEIR CONTENT, SERVICES AND
              TOOLS, DELAY OR REMOVE HOSTED CONTENT, AND TAKE TECHNICAL AND
              LEGAL STEPS TO PREVENT USERS FROM ACCESSING THE SERVICE IF WE
              BELIEVE THAT THEY ARE CREATING RISK OR POSSIBLE LEGAL LIABILITIES,
              INFRINGING THE INTELLECTUAL PROPERTY RIGHTS OF THIRD PARTIES, OR
              ACTING INCONSISTENTLY WITH THE LETTER OR SPIRIT OF OUR TERMS OR
               POLICIES. ADDITIONALLY, WE MAY, IN APPROPRIATE CIRCUMSTANCES AND
              AT OUR SOLE DISCRETION, SUSPEND OR TERMINATE ACCOUNTS OF USERS WHO
              MAY BE REPEAT INFRINGERS OF THIRD-PARTY INTELLECTUAL PROPERTY
              RIGHTS.
            </p>
            <p>
              K2 RESERVES THE RIGHT TO TERMINATE ANY ACCOUNT THAT HAS BEEN
              INACTIVE FOR 180 DAYS.
            </p>
            <p>
              K2 reserves the right to stop offering and/or supporting the
              Service or a particular game or part of the Service at any time,
              at which point your right to use the Service or a part thereof
              will be automatically terminated. In such event, K2 shall not be
              required to provide refunds, benefits or other compensation to
              users in connection with such discontinued Service. Termination of
              your Account can include disabling your access to the Service or
              any part thereof including any content you submitted or others
              submitted.
            </p>
            <p>
              You may terminate your Account at any time and for any reason by
               hzbcdeveloper@gmail.com that you wish to terminate your Account. 
            </p>
            <h1>2. Ownership</h1>
            <h3>2.1. Games and Service</h3>
            <p>
              All rights, title and interest in and to the Service (including
              without limitation any games, titles, computer code, themes,
              objects, characters, character names, stories, dialogue, catch
              phrases, concepts, artwork, animations, sounds, musical
              compositions, audio-visual effects, methods of operation, moral
              rights, documentation, in-game chat transcripts, character profile
              information, recordings of games played using a K2 game client,
              and the K2 game clients and server software) are owned by K2. K2
              reserves all rights, including without limitation, all
              intellectual property rights or other proprietary rights, in
              connection with its games and the Service.
            </p>
            <h3>2.2. Accounts</h3>
            <p>
              NOT WITHSTANDING ANYTHING TO THE CONTRARY HEREIN, YOU ACKNOWLEDGE
              AND AGREE THAT YOU SHALL HAVE NO OWNERSHIP OR OTHER PROPERTY
              INTEREST IN THE ACCOUNT, AND YOU FURTHER ACKNOWLEDGE AND AGREE
              THAT ALL RIGHTS IN AND TO THE ACCOUNT ARE AND SHALL FOREVER BE
              OWNED BY AND INURE TO THE BENEFIT OF K2.
            </p>
            <h3>2.3. Virtual Content</h3>
            <p>
              K2 owns, has licensed, or otherwise has rights to use all of the
              content that appears in the Service or in K2 games.
              Notwithstanding any provision to the contrary herein, you agree
              that you have no right or title in or to any content that appears
              in the Service, including without limitation the virtual items,
              content, features, goods, services or currency appearing or
              originating in any K2 game, whether earned in a game or purchased
              from K2, or any other attributes associated with an Account or
              stored on the Service. 
            </p>
            <h1>3. User Content</h1>
            <h3>3.1. Submission of User Content</h3>
            <p>
              "User Content" means any communications, images, sounds, and all
              the material, data, and information that you upload or transmit
              through a K2 game client or the Service, or that other users
              upload or transmit, including without limitation any chat text. By
              transmitting or submitting any User Content while using the
              Service, you affirm, represent and warrant that such transmission
              or submission is (a) accurate and not confidential or misleading;
              (b) not in violation of any laws, contractual restrictions or
              other third party rights, and that you have permission from any
              third party whose personal information or intellectual property is
              comprised in the User Content; (c) free of viruses, adware,
              spyware, worms or other malicious code; and (d) you acknowledge
              and agree that any of your personal information within such
              content will at all times be processed by K2 in accordance with
              its Privacy Policy.
            </p>
            <h3>3.1.1. Content Screening</h3>
            <p>
              K2 assumes no responsibility for the conduct of any user
              submitting any User Content and assumes no responsibility for
              monitoring the Service for inappropriate content or conduct. We do
              not, and cannot, pre-screen or monitor all User Content. Your use
              of the Service is at your own risk. By using the Service, you may
              be exposed to User Content that is offensive, indecent, or
              otherwise not in line with your expectations. You bear all risks
              associated with the use of any User Content available in
              connection with the Service. At our discretion, our
              representatives or technology may monitor and/or record your
              interaction with the Service or communications (including without
              limitation chat text) when you are using the Service.
            </p>
            <p>
              By entering into these Terms of Service, you hereby provide your
              irrevocable consent to such monitoring and recording. You
              acknowledge and agree that you have no expectation of privacy
              concerning the transmission of any User Content, including without
              limitation chat text or voice communications.
            </p>
            <p>
              K2 reserves the right in its sole discretion to review, monitor,
              prohibit, edit, delete, disable access to or otherwise make
              unavailable any User Content (including without limitation your
              User Content) without notice for any reason or for no reason at
              any time. If at any time K2 chooses, in its sole discretion, to
              monitor the Service, K2 nonetheless assumes no responsibility for
              User Content and assumes no obligation to modify or remove any
              inappropriate User Content. We have the right, but not the
              obligation, in our sole discretion to edit, refuse to post, or
              remove any User Content.
            </p>
            <h3>3.2. Information Use by Other Members of the Service</h3>
            <p>3.2.1. Public Discourse</p>
            <p>
              The Service may include various forums, blogs and chat features
              where you can post User Content, including your observations and
              comments on designated topics. K2 cannot guarantee that other
              members will not use the ideas and information that you share.
              Therefore, if you have an idea or information that you would like
              to keep confidential and/or don't want others to use, do not post
              it on the Service. K2 shall have no responsibility to evaluate,
              use or compensate you for any ideas or information you may choose
              to submit.
            </p>
            <p>3.2.2. Responsible For Your Own Content</p>
            <p>
              You are solely responsible for the information that you post on,
              through or in connection with the Service and that you provide to
              others. K2 may reject, refuse to post or delete any User Content
              for any or no reason, including, but not limited to, User Content
              that in the sole judgment of K2 violates these Terms of Service.
            </p>
            <p>3.2.3. Your License to K2</p>
            <p>
              You hereby grant to K2 an irrevocable, perpetual, transferable,
              fully paid-up, royalty-free, worldwide license (including the
              right to sublicense and assign to third party) and right to copy,
              reproduce, fix, adapt, modify, create derivative works from,
              manufacture, commercialize, publish, distribute, sell, license,
              sublicense, transfer, lease, transmit, publicly display, publicly
              perform, or provide access to electronically, broadcast,
              communicate to the public by telecommunication, display, perform,
              enter into computer memory, and use and practice, in any way, your
              User Content as well as all modified and derivative works thereof
              in connection with our provision of the Service, including
              marketing and promotions of the Service. You also hereby grant to
              K2 the right to authorize others to exercise any of the rights
              granted to K2 under these Terms of Service. You further hereby
              grant to K2 the unconditional, irrevocable right to use and
              exploit your name, likeness and any other information or material
              included in any User Content and in connection with any User
              Content, without any obligation to you. Except as prohibited by
              law, you waive any rights of attribution and/or any moral rights
              you may have in your User Content, regardless of whether your User
              Content is altered or changed in any manner. K2 does not claim any
              ownership rights in your User Content and nothing in these Terms
              of Service is intended to restrict any rights that you may have to
              use and exploit your User Content. K2 has no obligation to monitor
              or enforce your intellectual property rights in or to your User
              Content. 
            </p>
            <h3>3.3. User Interactions</h3>
            <p>
              You are solely responsible for your interactions with other users
              of the Service and any other parties with whom you interact
              through the Service and/or K2 games. K2 reserves the right, but
              has no obligation, to become involved in any way with these
              disputes. You will fully cooperate with K2 to investigate any
              suspected unlawful, fraudulent, or improper activity, including,
              without limitation, granting K2 access to any password-protected
              portions of your Account.
            </p>
            <p>
              If you have a dispute with one or more users, you release us (and
              our officers, directors, agents, subsidiaries, joint ventures and
              employees) from claims, demands and damages (actual and
              consequential) of every kind and nature, known and unknown,
              arising out of or in any way connected with such disputes. 
            </p>
            <h1>4. Fees and Purchase Terms</h1>
            <h3>4.1.Purchases </h3>
            <p>
              In the Service you may purchase, with "real world" money, a
              limited, personal, non-transferable, non-sublicensable, revocable
              right to use (a) virtual currency, including but not limited to
              virtual cash or diamonds, all for use in K2 games; (b) virtual
              in-game items, content or features; and (c) other goods or
              services (points a – c are jointly referred to as "Virtual
              Items"). You are only allowed to purchase Virtual Items from us or
              our authorized partners through the Service, and not in any other
              way.
            </p>
            <p>
              You expressly agree that we will start the performance of the
              purchase agreement of Virtual Items or any other digital content
              before the expiration of any statutory withdrawal period defined
              in applicable laws. You give explicit prior consent that the
              content you have purchased will be delivered to you before the
              withdrawal period ends and you thereby loose the statutory right
              of withdrawal.
            </p>
            <p>
              K2 may manage, regulate, control, modify or eliminate Virtual
              Items at any time, with or without notice. To the fullest extent
              under applicable law, K2 shall have no liability to you or any
              third party in the event that K2 exercises any such rights.
            </p>
            <p>
              The transfer of Virtual Items is prohibited except where expressly
              authorized in the Service. Other than as expressly authorized in
              the Service, you shall not sell, purchase, redeem or otherwise
              transfer Virtual Items to any person or entity or attempt any of
              the aforesaid, including but not limited to K2, another user or
              any third party.
            </p>
            <p>
              ALL PURCHASES AND REDEMPTIONS OF VIRTUAL ITEMS MADE THROUGH THE
              SERVICE ARE FINAL AND NON-REFUNDABLE EXCEPT WHERE REQUIRED UNDER
              APPLICABLE LAW. Please see our Parents Guide for more information
              regarding purchases made by minors.
            </p>
            <p>
              The provision of Virtual Items for use in K2 games is a service
              provided by K2 that commences immediately upon acceptance by K2 of
              your purchase.
            </p>
            <h3>4.2. Payment of Fees</h3>
            <p>
              You agree to pay all fees and applicable taxes incurred by you or
              anyone using an Account registered to you. K2 may revise the
              pricing for the Virtual Items offered through the Service at any
              time. YOU ACKNOWLEDGE THAT K2 IS NOT REQUIRED TO PROVIDE A REFUND
              FOR ANY REASON, AND THAT YOU WILL NOT RECEIVE MONEY OR OTHER
              COMPENSATION FOR UNUSED VIRTUAL ITEMS WHEN AN ACCOUNT IS CLOSED,
              WHETHER SUCH CLOSURE WAS VOLUNTARY OR INVOLUNTARY. 
            </p>

            <h1>5. Updates to the Service</h1>
            <p>
              You understand that the Service is an evolving one. K2 may require
              that you accept updates to the Service and to K2's games you have
              installed on your device or computer. You acknowledge and agree
              that K2 may update the Service and K2 games, with or without
              notifying you. You may need to update third party software from
              time to time in order to receive the Service and play K2 games. 
            </p>
            <h1>6. Disclaimer of Warranties</h1>
            <p>
              WITHOUT LIMITING K2'S LIABILITY UNDER SECTION 7 BELOW, THE SERVICE
              IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS FOR YOUR USE,
              WITHOUT WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING
              WITHOUT LIMITATION THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR
              A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, AND THOSE ARISING
              FROM COURSE OF DEALING OR USAGE OF TRADE. K2 DOES NOT WARRANT THAT
              YOU WILL BE ABLE TO ACCESS OR USE THE SERVICE AT THE TIMES OR
              LOCATIONS OF YOUR CHOOSING; THAT THE SERVICE WILL BE UNINTERRUPTED
              OR ERROR-FREE; THAT DEFECTS WILL BE CORRECTED; OR THAT THE GAME OR
              THE SERVICE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
            </p>
            <p>
              Some jurisdictions do not allow the exclusion of certain
              warranties. Accordingly, some of the above disclaimers may not
              apply to you. 
            </p>
            <h1>
              7. Limitation of Liability; Sole and Exclusive Remedy;
              Indemnification
            </h1>
            <p>
              TO THE MAXIMUM EXTENT PERMITTED BY LAW, K2 SHALL NOT BE LIABLE TO
              YOU FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, PUNITIVE
              OR OTHER SIMILAR DAMAGES, INCLUDING BUT NOT LIMITED TO LOSS OF
              REVENUES, LOST PROFITS, LOST DATA OR BUSINESS INTERRUPTION OR
              OTHER INTANGIBLE LOSSES (HOWEVER SUCH LOSSES ARE QUALIFIED),
              ARISING OUT OF OR RELATING IN ANY WAY TO THESE TERMS OF SERVICE OR
              THE SERVICE ITSELF, WHETHER BASED ON CONTRACT, TORT OR ANY OTHER
              LEGAL THEORY, AND WHETHER OR NOT K2 HAS BEEN ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGES. TO THE EXTENT NOT PROHIBITED BY LAW,
              K2 SHALL NOT BE LIABLE TO YOU FOR MORE THAN THE AMOUNT YOU HAVE
              PAID TO K2 IN ACCORDANCE WITH THESE TERMS OF SERVICE IN THE SIX
              (6) MONTHS IMMEDIATELY PRECEDING THE DATE ON WHICH YOU FIRST
              ASSERT A CLAIM. YOU ACKNOWLEDGE AND AGREE THAT IF YOU HAVE NOT
              PAID ANYTHING TO K2 DURING SUCH TIME PERIOD, YOUR SOLE REMEDY (AND
              K2'S EXCLUSIVE LIABILITY) FOR ANY DISPUTE WITH K2 IS TO STOP USING
              THE SERVICE AND TO CANCEL YOUR ACCOUNT. 
            </p>
            <p>
              NOTHING IN THESE TERMS OF SERVICE SHALL AFFECT THE STATUTORY
              RIGHTS OF ANY CONSUMER OR EXCLUDE OR RESTRICT ANY LIABILITY
              RESULTING FROM GROSS NEGLIGENCE OR WILLFUL MISCONDUCT OF K2 OR FOR
              DEATH OR PERSONAL INJURY ARISING FROM ANY NEGLIGENCE OR FRAUD OF
              K2.
            </p>
            <p>
              You agree to indemnify, defend and hold K2 (and our officers,
              directors, agents, subsidiaries, joint ventures and employees)
              harmless from any claim, demand, damages or other losses,
              including reasonable attorneys' fees, asserted by any third-party
              resulting from or arising out of your use of the Service, or any
              breach by you of these Terms of Service, however the foregoing
              does not apply if the infringement of rights is not attributable
              to your intentional or negligent behavior. 
            </p>
            <h1>8. Dispute Resolution</h1>
            <p>
              You and K2 agree that the processes for dispute resolution
              described in this agreement will apply to any dispute or claims
              related to these Terms of Service, the Privacy Policy, or the
              Service. Disputes include any claims of any kind, including but
              not limited to legal, equitable, or statutory claims. Processes
              for dispute resolution will apply even if you stop using your
              Account, delete your Account, or stop using the Service. They will
              also apply to disputes that arose before we entered into this
              agreement.
            </p>
            <p>
              Sections 8.1 to 8.6 apply to you if you are a resident of the
              United States or Canada. They do not apply to you if you are a
              resident of any other country.
            </p>
            <h3>8.1. Informal Dispute Resolution</h3>
            <p>
              You must try to informally resolve any dispute directly with K2
              for at least thirty (30) days before you start an arbitration. The
              informal dispute resolution process starts when you give K2
              written notice of the dispute through hzbcdeveloper@gmail.com.
            </p>
            <h3>8.2. Arbitration</h3>
            <p>
              Agreement You and K2 agree to resolve any disputes exclusively in
              final and binding arbitration as follows:
            </p>
            <p>
              Either you or K2 may choose to submit any dispute for resolution
              exclusively by final and binding arbitration unless the claim is
              within the exceptions described below. If you or K2 brings a claim
              in court that can be resolved by arbitration under this section,
              then either party can ask the court to order the parties to
              resolve the claim by arbitration. The arbitrator will have the
              exclusive authority to decide whether any portion of Section 8
              (“Dispute Resolution”) is valid or enforceable, or whether it
              applies to a claim.
            </p>
            <p>
              An arbitration proceeding will be held before a neutral
              arbitrator. This means you and K2 agree to give up the right to
              resolve the dispute in a trial before a judge or jury. Arbitration
              has different rules than more formal lawsuits. For example, the
              ability to force the other side to share information may be more
              limited than the process called discovery in formal lawsuits.
              After the arbitrator decides the outcome, that decision will be
              final. You or K2 can ask the arbitrator to put a decision or award
              and the reasons for it in writing. Either of us can ask a court to
              confirm or enter the arbitrator’s final decision or award, which
              will make it the same as a court judgment. You and K2 will
              generally not be able to change the outcome of arbitration through
              courts outside of very limited circumstances. 
            </p>
            <h3>8.3. Arbitration Process</h3>
            <p>
              The arbitration will be run by the American Arbitration
              Association (“AAA”). AAA’s rules and procedures will be used for
              the arbitration, including the Consumer Arbitration Rules. But if
              there is a conflict between these Terms of Service and AAA’s rules
              and procedures, then we will follow these Terms of Service. To
              review AAA’s Rules or to start arbitration, you can go to AAA’s
              website https://adr.org/. If either of us decide to start
              arbitration, we agree to provide the other party with a written
              Demand for Arbitration as specified in the AAA Rules.
            </p>
            <p>
              The fees for arbitration will be determined by AAA’s Consumer
              Arbitration Rules. If the arbitrator decides that those fees are
              excessive, K2 will pay the fees. Each side will pay their own
              attorneys’ fees and costs unless the claims allow for to the
              prevailing party to recover attorneys’ fees and costs, in which
              case the arbitrator may award them under the applicable law. If
              either party unsuccessfully challenges the validity of the
              arbitrator’s decision or award through a subsequent court case,
              the unsuccessful party shall pay the opposing party’s costs and
              attorneys’ fees associated with the challenge.
            </p>
            <p>
              The arbitration will take place either in San Francisco,
              California, USA or in the county or province where you reside.
            </p>
            <h3>8.4 Exceptions to Agreement to Arbitrate</h3>
            <p>
              You and K2 agree that the arbitration agreement in Section 8.2
              will not apply to the following disputes:
            </p>
            <p>
              * Claims about K2’s intellectual property, such as claims to
              enforce, protect, or concerning the validity of K2’s copyrights,
              trademarks, trade dress, domain names, patents, trade secrets, or
              other intellectual property rights.
            </p>
            <p>* Claims related to piracy or tortious interference.</p>
            <p>
              * Claims that are not subject to an arbitration agreement as a
              matter of law and are not preempted by federal law that would
              allow for an agreement to arbitration.
            </p>
            <p>* Claims in small claims court.</p>
            <p>
              Any dispute not subject to arbitration under these exceptions
              shall be resolved by a court of competent jurisdiction as
              described in Section 10 (“Venue for Disputes Not Subject to
              Arbitration”).
            </p>
            <h3>8.5 No Class Actions</h3>
            <p>
              You and K2 agree that we can only bring claims against each other
              on an individual basis.That means:
            </p>
            <p>
              * You cannot bring a claim against K2 as a plaintiff or class
              member in a class, collective, consolidated, or representative
              action.
            </p>
            <p>
              * The arbitrator cannot combine any other person’s claims with
              yours into a single case or preside over any class, collective,
              consolidated, or representative arbitration proceeding.
            </p>
            <p>
              * The arbitrator’s decision or award in your case will not apply
              to anyone else and cannot be used to decide other people’s
              disputes.
            </p>
            <p>
              If this section (Section 8.5 “No Class Actions”) is found to be
              unenforceable or invalid, then the entirety of Section 8,
              including Sections 8.1 to 8.6, shall be void.
            </p>
            <h3>
              8.6 Opt-Out of Arbitration Agreement and No Class Actions
              Provisions
            </h3>
            <p>
              You can choose to opt out of and not be bound by the Arbitration
              Agreement and No Class Action provisions above (Sections 8.1 to
              8.5) by sending written notice of your decision to opt out to
              hzbcdeveloper@gmail.com with the subject line “ARBITRATION AND
              CLASS ACTION WAIVER OPT-OUT.” You must send us this notice within
              thirty (30) days of your first use of the Service or availability
              of this opt-out, whichever is later. If you do not send us a
              notice within that time, you will be bound to arbitrate disputes
              in accordance with the terms of these paragraphs. If you opt out
              of these arbitration provisions, K2 also will not be bound by
              them.
            </p>
            <h3>8.7 Alternative and Online Dispute Resolution</h3>
            <p>
              If you are a consumer based in the EU or EEA, you may make use of
              the Online Dispute Resolution platform  offered by the European
              Commission as an out-of-court alternative to resolve disputes. 
              You may also recourse to alternative dispute resolution and refer
              a dispute to the consumer disputes board or other corresponding
              out-of-court institution in your domicile.
            </p>
            <h1>9. Applicable Law</h1>
            <p>
              If you are a resident of the United States or Canada: (1) the
              United States Federal Arbitration Act (including its procedural
              provisions) governs the interpretation and enforcement of the
              agreement to arbitrate and no class actions provisions of Section
              8, and (2) any dispute arising out of or related to these Terms of
              Service, the Privacy Policy, or the Service shall be governed in
              all respects by the laws of the State of California, USA without
              regard to conflict of law provisions. 
            </p>
            <p>
              If you are a resident outside of the United States and Canada, you
              agree that any claim or dispute arising out of or related to these
              Terms of Service, the Privacy Policy, or the Service shall be
              governed by the laws of Finland without regard to conflict of law
              provisions. 
            </p>
            <h1>10. Venue for Disputes Not Subject to Arbitration</h1>
            <p>
              If you are a resident of the United States or Canada, you agree
              that any claim or dispute you may have against K2 that is not
              subject to arbitration under Section 8 must be resolved
              exclusively by a federal or state court located in San Francisco,
              California. You and K2 consent to venue and personal jurisdiction
              in San Francisco, California for all such claims or disputes.
            </p>
            <p>
              If you are a resident outside of the United States and Canada, you
              agree that any claim or dispute you may have against K2 must be
              resolved exclusively by a court located in Helsinki, Finland. 
            </p>
            <h1>11. Severability</h1>
            <p>
              You and K2 agree that if any portion of these Terms of Service or
              of the K2 Privacy Policy is found illegal or unenforceable, in
              whole or in part by any court of competent jurisdiction, such
              provision shall, as to such jurisdiction, be ineffective solely to
              the extent of such determination of invalidity or unenforceability
              without affecting the validity or enforceability thereof in any
              other manner or jurisdiction and without affecting the remaining
              provisions of the terms, which shall continue to be in full force
              and effect. 
            </p>
            <h1>12. General Provisions</h1>
            <h3>12.1. Assignment</h3>
            <p>
              K2 may assign or delegate these Terms of Service and/or the
              K2 Privacy Policy, in whole or in part, to any person or entity at
              any time with or without your consent. You may not assign or
              delegate any rights or obligations under the Terms of Service
              or Privacy Policy without K2's prior written consent, and any
              unauthorized assignment and delegation by you is ineffective.
            </p>
            <h3>12.2. Supplemental Policies</h3>
            <p>
              K2 may publish additional policies related to specific services
              such as forums, contests, or loyalty programs. Your right to use
              such services is subject to those specific policies and these
              Terms of Service.
            </p>
            <h3>12.3. Entire Agreement</h3>
            <p>
              These Terms of Service, any supplemental policies and any
              documents expressly incorporated by reference herein (including
              the K2 Privacy Policy), contain the entire understanding of you
              and K2, and supersede all prior understandings of the parties
              hereto relating to the subject matter hereof, whether electronic,
              oral or written, or whether established by custom, practice,
              policy or precedent, between you and us with respect to the
              Service.
            </p>
            <h3>12.4. No Waiver</h3>
            <p>
              The failure of K2 to require or enforce strict performance by you
              of any provision of these Terms of Service or the K2 Privacy
              Policy or failure to exercise any right under them shall not be
              construed as a waiver or relinquishment of K2's right to assert or
              rely upon any such provision or right in that or any other
              instance. 
            </p>
            <p>
              The express waiver by K2 of any provision, condition, or
              requirement of these Terms of Service or the K2 Privacy
              Policy shall not constitute a waiver of any future obligation to
              comply with such provision, condition or requirement.
            </p>
            <p>
              Except as expressly and specifically set forth in this these Terms
              of Service, no representations, statements, consents, waivers, or
              other acts or omissions by K2 shall be deemed a modification of
              these Terms of Service nor legally binding, unless documented in
              physical writing, hand signed by You and a duly appointed officer
              of K2.
            </p>
            <h3>12.5. Notices</h3>
            <p>
              We may notify you via postings in our games, on k2games.cc, via
              e-mail or any other communications means to contact information
              you provide to us.
            </p>
            <h3>12.6. Equitable Remedies</h3>
            <p>
              You acknowledge that the rights granted and obligations made under
              these Terms of Service to K2 are of a unique and irreplaceable
              nature, the loss of which shall irreparably harm K2 and which
              cannot be replaced by monetary damages alone so that K2 shall be
              entitled to injunctive or other equitable relief (without the
              obligations of posting any bond or surety or proof of damages) in
              the event of any breach or anticipatory breach by you. 
            </p>
            <p>
              You irrevocably waive all rights to seek injunctive or other
              equitable relief, or to enjoin or restrain the operation of the
              Service or any K2 game, exploitation of any advertising or other
              materials issued in connection therewith, or exploitation of the
              Service or any content or other material used or displayed through
              the Service and agree to limit your claims to claims for monetary
              damages, limited by Section 7 (if any).
            </p>
            <h3>12.7. Force Majeure</h3>
            <p>
              K2 shall not be liable for any delay or failure to perform
              resulting from causes outside the reasonable control of K2,
              including without limitation any failure to perform hereunder due
              to unforeseen circumstances or cause beyond K2's control such as
              acts of God, war, terrorism, riots, embargoes, acts of civil or
              military authorities, fire, floods, accidents, strikes, or
              shortages of transportation facilities, fuel, energy, labor or
              materials.
            </p>
          </div>
          <div
            class="content"
            v-else-if="active === 1">
            <p>Effective Date: June 1st, 2022</p>

            <p>
              Thank you for playing our games! This Privacy Policy describes:
            </p>
            <p>*The ways we collect personal data about you and why we do so</p>
            <p>*How we use your personal data, and</p>
            <p>*The choices you have about your personal data.</p>
            <p>
              This Privacy Policy applies to K2’s games, store, sites and
              related services, which we here collectively call the Service. We
              may periodically update this Privacy Policy by posting a new
              version on k2games.cc. If we make any material changes, we will
              notify you as required under applicable law, including by posting
              a notice in the Service prior to the change becoming effective.
              Your continued use of the Service after the effective date will be
              subject to the new Privacy Policy.
            </p>
            <h1>CONTACT US</h1>
            <p>
              If you have questions about data protection, or if you have any
              requests for resolving issues with your personal data, we
              encourage you to primarily contact us through the support features
              within each BaoChuan game, so we can reply to you more quickly.
              Alternatively, you may contact:
            </p>
            <p>Attn: Privacy</p>
            <p>email: hzbcdeveloper@gmail.com</p>
            <h1>THE DATA WE COLLECT</h1>
            <p>
              The categories of personal data we collect depend on the Services
              you use, and the requirements of applicable law.
            </p>
            <h3>Data you provide us directly.</h3>
            <p>
              *Contact information (such as name and email address or phone
              numbers)
            </p>
            <p>*Player name or tag and password</p>
            <p>*Profile information (such as profile photo)</p>
            <p>
              *Your messages and other content you submit when you use the
              Service (such as chat logs and player support tickets)
            </p>
            <p>
              *Data you provide in connection with surveys, promotions,
              sweepstakes, competitions and other events
            </p>
            <p>
              *Other data you choose to give us (such as when you participate in
              (K2 Creators or K2 Make)
            </p>
            <p>
              *Our payment service providers will collect your payment details
              in connection with any purchases you make through the K2 Store
            </p>
            <h3>Data we collect automatically.</h3>
            <p>
              *Data about your account and game progress, including in most
              cases an automatically created internal account ID
            </p>
            <p>
              *Your IP address and mobile device identifiers (such as your
              device or advertising ID)
            </p>
            <p>
              *Data about your device, such as device name and operating system,
              browser type and language, internet service provider, and mobile
              carrier
            </p>
            <p>
              *Data we collect with cookies and similar technologies (see more
              below)
            </p>
            <p>*Approximate location data (as derived from IP address)</p>
            <p>
              *Data about your use of the Service, such as gameplay data,
              purchases made and your interactions with other players inside the
              Service
            </p>
            <p>
              You also have the option to create a K2 ID using your email
              address or other contact information.
            </p>
            <h3>Data we collect from our partners.</h3>
            <p>
              We also use third-party partners, such as social networking sites,
              data analytics providers and advertising networks to supplement
              information we have about you, such as:
            </p>
            <p>
              *Data we receive if you link a third-party tool with the Service
              (such as LINE, Facebook, WeChat or Kakao)
            </p>
            <p>
              *Demographic data (such as to determine the coarse location of
              your IP address)
            </p>
            <p>
              *Data to fight fraud (such as refund abuse in games or click fraud
              in advertising)
            </p>
            <p>
              *Data from platforms that the games run on or data from payment
              service providers (such as payment verification data)
            </p>
            <p>
              *Data for advertising and analytics purposes (such as surveys), so
              we can provide you a better Service
            </p>
            <p>
              *Data that you have made available to us from a third party
              service (such as LINE, Facebook, WeChat or Kakao) via your privacy
              settings
            </p>
            <h1>WHY DO WE COLLECT YOUR DATA</h1>
            <p>We use your data for a variety of business purposes, such as:</p>
            <h3>To make the Service work.</h3>
            <p>To perform the contract, we process data necessary to</p>
            <p>
              *Create accounts and allow you to play our games and use our
              Service
            </p>
            <p>*Operate the Service</p>
            <p>*Verify and confirm payments</p>
            <p>*Provide and deliver products and services you request</p>
            <p>*Send you Service-related communications</p>
            <h3>To make the Service more suitable for our players.</h3>
            <p>
              To provide a great Service to our players, we have a legitimate
              interest to collect and process necessary data to
            </p>
            <p>*Update and develop player profiles</p>
            <p>*Develop and improve the Service and player experience</p>
            <p>*Manage our relationship with you</p>
            <p>*Provide social features as part of the Service</p>
            <p>*Customize your Service experience</p>
            <p>
              *Respond to your comments and questions and provide player support
            </p>
            <p>
              *Provide you K2 offers in the Service as well as in other websites
              and services (including by email)
            </p>
            <p>
              *Send you related information, such as updates, security alerts,
              and support messages
            </p>
            <p>*Enable you to communicate with other players</p>
            <h3>To show personalized advertisements.</h3>
            <p>
              To show you personalized advertisements in the Service and
              elsewhere (including email) we have a legitimate interest to
              process necessary data to
            </p>
            <p>
              *Track the content you access in connection with the Service and
              your online behavior
            </p>
            <p>*Deliver, target and improve our advertising and the Service</p>
            <p>
              For information on how to opt-out from personalized
              advertisements, see section Your Rights and Options' below.
            </p>
            <h3>To keep the Service safe and fair.</h3>
            <p>
              In order to keep the Service and its social features safe and
              fair, to fight fraud and ensure acceptable use otherwise, we have
              a legitimate interest to process necessary data to
            </p>
            <p>
              *Analyze and monitor use of the Service and its social features
            </p>
            <p>*Moderate chats either automatically or manually</p>
            <p>*Take action against fraudulent or misbehaving players</p>
            <h3>To analyze, profile, and segment.</h3>
            <p>
              In all of the above cases and purposes, we may analyze, profile
              and segment all collected data.
            </p>
            <h3>With your consent.</h3>
            <p>
              With your consent, we may process your data for additional
              purposes.
            </p>
            <p>
              We may also collect and use your device identifiers to display and
              personalize in-game ads as explained below. Where applicable, we
              may collect your advertising identifier, IP address and device ID
              for these purposes. Under applicable privacy laws, including the
              General Data Protection Regulation (GDPR), we are required to ask
              your consent to do so.
            </p>
            <p>
              This data is shared with your consent so that our ad partners can
              deliver the right ad and optimize the ad content to make sure you
              don’t see the same ad multiple times. We share your device
              identifiers with selected partners for such ad delivery and
              personalization.
            </p>
            <p>
              The partners operate the ad network and mediation platform, as
              well as facilitate and participate in ad inventory bidding. The
              partners may also use the device identifiers for fraud detection
              purposes based on their legitimate interest. For more information,
              please check the partners’ privacy policies.
            </p>
            <p>Google Privacy Policy: https://policies.google.com/privacy</p>
            <h1>WHO CAN SEE YOUR DATA</h1>
            <p>
              Apart from K2, your data can be accessed by others in the
              following situations:
            </p>
            <h3>Other players and users.</h3>
            <p>
              Social features are a core component of our games. Other players
              and users may, for example, see your profile data, in-game
              activities and read the messages you have posted.
            </p>
            <h3>Our service providers.</h3>
            <p>
              K2 has vendors who help us to provide the Service. These vendors
              process your data only at and according to K2´s instructions to
              provide the Service, and perform tasks such as hosting, player
              support, advertising, analytics and fraud prevention.
            </p>
            <h3>Other companies and public authorities.</h3>
            <p>
              In order to verify payments (with payment providers such as
              Paypal) and combat fraud and illegal activity, we may process and
              disclose data with other companies and organizations and provide
              it to public authorities in response to lawful requests.
            </p>

            <p>
              We may also disclose your data based on your consent, to comply
              with the law or to protect the rights, property or safety of us,
              our players or others.
            </p>
            <h3>Advertising and Social Media partners.</h3>
            <p>
              The Service includes features from our partners, such as social
              media interaction tools, functionalities through application
              programming interfaces (APIs) or software development kits (SDKs)
              and in-game advertising. These partners include:
            </p>
            <p>Facebook</p>
            <p>Privacy Policy: https://www.facebook.com/about/privacy</p>
            <p>Google</p>
            <p>Privacy Policy: https://policies.google.com/privacy</p>
            <p>
              These partners may access your data and operate under their own
              privacy policies. We encourage you to check their privacy policies
              to learn more about their data processing practices.
            </p>

            <p>
              These partners may access data regarding your activities and your
              device (such as your IP address, mobile identifiers, page(s)
              visited, location, time of day). We may also combine and share
              data we have collected about you with third-party advertising
              partners. These advertising partners may use this data (and
              similar data collected from other services) for purposes of
              delivering targeted advertisements to you when you visit
              third-party services within their networks. These partners may
              operate under their own privacy policies. This practice is
              commonly referred to as “interest-based advertising” or “online
              behavioral advertising.” If you prefer not to share your personal
              data with third-party advertising partners, you may follow the
              instructions in “Your Rights and Options” below.
            </p>
            <h1>YOUR RIGHTS AND OPTIONS</h1>
            <h3>Push Notifications</h3>
            <p>
              We may send you push notifications through our mobile
              applications. You may at any time opt-out from receiving these
              types of communications by changing the settings on your mobile
              device.
            </p>
            <h3>Opt-out of targeted advertising.</h3>
            <p>
              You can opt-out of interest-based advertising on mobile
              applications by checking the privacy settings of your Android or
              iOS device and turning off “Allow Apps to Request to Track” or
              selecting "Limit Ad Tracking" (Apple iOS) or "Opt-out of Interest
              Based Ads" (Android).
            </p>
            <p>
              You can also opt out from personalized in-game offers through the
              options provided in the game settings (where applicable).
            </p>
            <h3>Access the personal data we hold about you.</h3>
            <p>
              If you request, we will provide you a copy of your personal data
              in an electronic format.
            </p>
            <h3>Your other rights.</h3>
            <p>
              You also have the right to correct your data, have your data
              deleted, object how we use or share your data, and restrict how we
              use or share your data. You can always withdraw your consent.
            </p>

            <p>
              We will respond to all requests within a reasonable timeframe. If
              you have an unresolved privacy or data use concern that we have
              not addressed satisfactorily, you may also contact your local data
              protection authority within the European Economic Area,
              Switzerland or UK for unresolved complaints.
            </p>

            <p>
              If you wish to exercise any of your data subject rights described
              above, please use the support options described in “Contact Us”
              above. We will respond to all requests in accordance with
              applicable laws. To protect your privacy, we may also take
              additional steps to verify your identity before fulfilling your
              request, such as by requesting you provide us a purchase receipt
              or other account information, asking you to link your email
              address with your game account (for example through K2 ID), or
              asking you to answer questions regarding your activities on our
              Services. Once you have verified your identity, you may also
              designate an authorized agent to exercise your rights on your
              behalf by providing the agent’s contact information to our
              customer support staff, as required by applicable law.
            </p>

            <p>
              You have the right not to receive discriminatory treatment as a
              result of your exercise of these rights.
            </p>
            <h1>HOW DO WE PROTECT YOUR DATA</h1>
            <h3>Security Safeguards.</h3>
            <p>
              In order to help ensure a secure and safe player experience, we
              are continuously developing and implementing administrative,
              technical and physical security measures to protect your data from
              unauthorized access or against loss, misuse or alteration.
            </p>
            <h3>Data retention.</h3>
            <p>
              We retain your data for as long as your account is active or as
              needed to provide you the Service. We may for example periodically
              de-identify unused game accounts, and we may regularly review and
              de-identify unnecessary data.
            </p>
            <p>
              Note that if you ask us to remove your personal data, we will
              retain your data as necessary for our legitimate business
              interests, such as to comply with our legal obligations, resolve
              disputes, and enforce our agreements.
            </p>
            <h1>CHILDREN</h1>
            <p>
              Protecting children’s privacy online is very important to us.
              Generally, our Services are not directed to children under 13 (or
              other age as required by local law), and the Services do not
              knowingly collect personal data from children, except as described
              in the following paragraph.
            </p>

            <p>
              For games that are not directed at children but nonetheless may
              appeal to them, we take additional steps to limit access to these
              Services based on the player’s age. For these Services, when a
              player indicates they are under 13 (or the applicable age in their
              territory), we will limit their access to certain features, such
              as social media and chat features, as well as certain types of
              notification alerts. We also limit the processing of personal data
              to only what is necessary to support the internal operations of
              our Services.
            </p>

            <p>
              Please note that for some of our Services we may use a higher age
              limit (such as 16) than described in the above two paragraphs. We
              do this to provide a safe game experience for our players and to
              protect Young Players.
            </p>

            <p>
              If you are a parent or guardian and wish to review personal data
              collected from your child, or have that information modified or
              deleted, you may contact us as described above. If we become aware
              that a child has provided us with personal data contrary to the
              above, we will delete any personal data we have collected, unless
              we have a legal obligation to keep it, and terminate the child’s
              account and/or revert them to the underage experience, as
              applicable.
            </p>
          </div>
        </transition>
      </div>
    </div>
    <back-to-top></back-to-top>
  </div>
</template>

<script>
import TopNav from '@/components/top-nav/index';
import BackToTop from '@/components/BackToTop/index';

export default {
  name: 'Agreements',
  components: {
    TopNav,
    BackToTop
  },
  data() {
    return {
      navList: [
        {
          label: 'TERMS OF SERVICE',
        },
        {
          label: 'PRIVACY POLICY',
        },
      ],
      active: 0,
      title: '',
      logoShow: false,
    };
  },
  methods: {
    sildeChange(index) {
      this.active = index;
      this.title = this.navList[index].label;
    },
    navChange(item) {
      this.$router.push({
        path: '/home',
        query: {
          navname: item.name,
        },
      });
    },
  },
  mounted() {
    this.$route.query.name === 'terms' ? (this.active = 0) : (this.active = 1);
    this.title = this.navList[this.active].label;
  },
};
</script>

<style lang="scss" scoped>
.agreements {
  width: 100%;
  height: auto;
  padding-top: 210px;
  background: url('#{$cdnUrl}/assets/images/pc/bg.png') repeat top center;
  background-size: cover;
  overflow: hidden;
  position: relative;
  .main {
    display: flex;
    flex-wrap: nowrap;
    padding: 0 16% 0 30px;
  }
  .center {
    padding: 50px;
    margin: 0 auto;
    background: url('#{$cdnUrl}/assets/images/pc/center_bg.png') repeat;
    background-size: cover;
  }
  .silde-nav {
    margin-right: 30px;
  }
  .nav-item {
    width: 300px;
    height: 80px;
    background: url('#{$cdnUrl}/assets/images/pc/title_bg.png') no-repeat;
    cursor: pointer;
    font-size: 24px;
    color: #c52aff;
    text-align: center;
    line-height: 80px;
    margin-bottom: 30px;
    font-weight: 500;
  }
  .active {
    background: url('#{$cdnUrl}/assets/images/pc/title_active.png')
      no-repeat;
    color: #fff;
  }
  .title {
    color: #fff;
    font-size: 70px;
    font-weight: 500;
    margin: 20px 0;
  }
  .content {
    color: #fff;
    font-size: 20px;
    h1 {
      line-height: 100px;
      font-size: 32px;
    }
    h3 {
      line-height: 80px;
      font-size: 25px;
    }
    p {
      line-height: 30px;
    }
  }
}
/* fade-transform */
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all 0.5s;
}

.fade-transform-enter {
  opacity: 0;
  transform: translateX(-30px);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
