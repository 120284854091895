import { isMobile } from '@/assets/js/utils';

(function (doc, win) {
  var docEl = doc.documentElement;
  let resizeEvt =
      'orientationchange' in window ? 'orientationchange' : 'resize',
    recalc = function () {
      var clientWidth = docEl.clientWidth;
      if (!clientWidth) return;
      if (isMobile()) {
        docEl.style.fontSize = 100 * (clientWidth / 375) + 'px';
      } else {
        docEl.style.fontSize = 100 * (clientWidth / 1920) + 'px';
      }
    };

  if (!doc.addEventListener) return;
  win.addEventListener(resizeEvt, recalc, false);
  doc.addEventListener('DOMContentLoaded', recalc, false);
})(document, window);
