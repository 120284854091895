var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('top-nav',{attrs:{"active":_vm.activeNav},on:{"change":_vm.navChange}}),_c('div',{staticClass:"home-main",attrs:{"id":"home"}},[_c('video',{staticClass:"video-js vjs-default-skin vjs-big-play-centered",attrs:{"id":"myPlayer","playsinline":"","webkit-playsinline":"","loop":"","controls":"","muted":"","preload":"auto","autoplay":"autoplay","controlsList":"nodownload","oncontextmenu":"return false"},domProps:{"muted":true}}),_c('div',{staticClass:"home-mask"}),_c('div',{staticClass:"home-center"},[_c('div',{staticClass:"video-play",on:{"click":_vm.videoPlay}},[_c('div',{staticClass:"video-play-circle animate"}),_c('div',{staticClass:"video-play-btn"})]),_c('div',{staticClass:"ewm-main"},[_vm._m(0),_c('div',{staticClass:"ewm-btns"},_vm._l((_vm.channelList),function(item,index){return _c('div',{key:index,class:['ewm-btn', item.name],on:{"click":function($event){return _vm.handleClick(item)}}})}),0)])]),_c('video-player',{ref:"videoPlayer",attrs:{"isShow":_vm.isShow},on:{"close":_vm.closePlayer}}),_c('div',{staticClass:"mask bottom-mask"})],1),_c('div',{staticClass:"einherjar-main",attrs:{"id":"einherjar"}},[_c('div',{staticClass:"mask top-mask"}),_c('div',{staticClass:"title title-two"}),_c('swiper',{ref:"mySwiper",attrs:{"option":_vm.swiperOption,"circular":"true","loop":"true"}},_vm._l((_vm.bannerlist),function(item,index){return _c('swiper-slide',{key:index,class:['banner-item', `banner-item-${item.name}`]},[_c('span',[_vm._v(_vm._s(item.info))]),_c('div',{class:['gif', `gif-${item.name}`]})])}),1),_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"},_vm._l((_vm.bannerlist),function(item,index){return _c('div',{key:index,class:[
          _vm.activeIndex === index
            ? `btn-${item.name}-active`
            : `btn-${item.name}`,
          'btn',
        ],on:{"mouseenter":function($event){return _vm.mouseenter(index)},"mouseleave":function($event){return _vm.mouseleave(index)}}})}),0),_c('div',{staticClass:"mask bottom-mask"})],1),_c('div',{staticClass:"news-main",attrs:{"id":"news"}},[_c('div',{staticClass:"mask top-mask"}),_c('div',{staticClass:"title title-three"}),_c('div',{staticClass:"news-content"},[_c('div',{staticClass:"news-bg"},[_c('div',{staticClass:"more",on:{"click":_vm.handleMore}}),_c('div',{staticClass:"new-list-tab"},_vm._l((_vm.tabList),function(item,index){return _c('div',{key:index,class:[
              'tab-items',
              `tab-items${index}`,
              _vm.tabIndex === index ? `tabactive${index}` : '',
            ],on:{"click":function($event){return _vm.tabClick(index)}}})}),0),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.showList)?_c('div',{staticClass:"list-main"},_vm._l((_vm.newslist),function(item,index){return _c('div',{key:index,staticClass:"list-item"},[_c('div',{staticClass:"list-item-left",on:{"click":function($event){return _vm.handleItem(item)}}},[_c('div',{staticClass:"time"},[_vm._v(_vm._s(item.published_at))]),_c('label',[_vm._v("["+_vm._s(_vm.tabList[_vm.tabIndex].name)+"]")]),_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{class:[
                    'item-icon',
                    item.flag == 1
                      ? 'item-hot'
                      : item.flag == 2
                      ? 'item-new'
                      : '',
                  ]})])])}),0):_vm._e()])],1),_c('swiper',{ref:"myNewsSwiper",staticClass:"myNewsSwiper",attrs:{"option":_vm.newsSwiperOption}},_vm._l((_vm.newsBanner),function(item,index){return _c('swiper-slide',{key:index,staticClass:"new-item"},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":item.pic},on:{"click":function($event){return _vm.handelChangeUrl(item)}}})])}),1),_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"},_vm._l((_vm.newsBanner),function(item,index){return _c('div',{key:index,class:[_vm.newsActive === index ? `news-btn-active` : '', 'news-btn'],on:{"mouseenter":function($event){return _vm.newsBannerEnter(index)},"mouseleave":function($event){return _vm.newsBannerLeave(index)}}})}),0)],1)]),_c('div',{staticClass:"community-main",attrs:{"id":"community"}},[_c('div',{staticClass:"mask top-mask"}),_c('div',{staticClass:"title title-four"}),_c('div',{staticClass:"center"},_vm._l((_vm.linklist),function(item,index){return _c('div',{key:index,class:['center-item', `center-${item.name}`],on:{"click":function($event){return _vm.handleClick(item)}}})}),0)]),_c('index-footer'),_c('back-to-top')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ewm-bg"},[_c('div',{staticClass:"ewm-img"})])
}]

export { render, staticRenderFns }