<template>
  <div class="newslist">
    <top-nav
      @change="navChange"
      :active="activeNav"></top-nav>
    <div class="top_bg"></div>
    <div class="top_logo"></div>

    <div class="lists">
      <div class="new-list-tab">
        <div
          v-for="(item, index) in tabList"
          :key="index"
          :class="[
            'tab-items',
            `tab-items${index}`,
            tabIndex === index ? `tabactive${index}` : '',
          ]"
          @click="tabClick(index)"></div>
      </div>
      <transition name="slide-fade">
        <div
          class="list-main"
          v-if="showList">
          <div
            class="list-item"
            v-for="(item, index) in newslist"
            :key="index"
            @click="handleItem(item)">
            <div class="news-img">
              <img
                :src="item.pic"
                style="width: 100%" />
            </div>
            <div class="list-item-right">
              <span class="text">
                {{ item.name }}
              </span>
              <div class="item-bottom">
                <span class="time">{{ item.published_at }}</span>
                <label>{{ tabList[tabIndex].name }}</label>
              </div>
            </div>
          </div>
        </div>
      </transition>
      <div
        class="more"
        @click="handleMore"
        v-if="showMore"></div>
    </div>
    <div class="bottom_bg"></div>
    <back-to-top class="to-top"></back-to-top>
    <index-footer></index-footer>
  </div>
</template>

<script>
import TopNav from '@/components/top-nav/index';
import BackToTop from '@/components/BackToTop/index';
import IndexFooter from '@/components/Footer/index';
import { getArticleLists } from '@/api/index';

export default {
  name: 'Newslist',
  data() {
    return {
      activeNav: 'news',
      newslist: [],
      tabIndex: 0,
      page: 1,
      tabList: [
        { name: 'LATEST' },
        {
          name: 'NEWS',
        },
        {
          name: 'ACTIVITY',
        },
        {
          name: 'NOTICE',
        },
      ],
      showMore: false,
      showList: true,
    };
  },
  components: {
    TopNav,
    BackToTop,
    IndexFooter,
  },
  mounted() {
    this.getArticleLists(this.tabIndex, this.page);
  },
  methods: {
    navChange(item) {
      this.$router.push({
        path: '/home',
        query: {
          navname: item.name,
        },
      });
    },

    tabClick(index) {
      this.tabIndex = index;
      this.page = 1;
      this.newslist = [];
      this.showList = false;
      this.getArticleLists(index, this.page);
    },
    handleItem(item) {
      this.$router.push({
        path: '/info',
        query: {
          id: item.id,
        },
      });
    },
    getArticleLists(type, page) {
      getArticleLists({
        game_id: 1,
        type: type,
        page: page,
        limit: 6,
      }).then((res) => {
        this.newslist = [...this.newslist, ...res.data.list];
        if (this.newslist.length <= 5 || res.data.list.length === 0) {
          this.showMore = false;
        } else {
          this.showMore = true;
        }
        this.showList = true;
      });
    },
    handleMore() {
      this.page++;
      this.getArticleLists(this.tabIndex, this.page);
    },
  },
};
</script>

<style lang="scss" scoped>
.newslist {
  width: 100%;
 background: url('#{$cdnUrl}/assets/images/pc/newmore_bg.png') no-repeat;
  background-size: cover;
  position: relative;
  .top_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 382px;
    height: 439px;
   background: url('#{$cdnUrl}/assets/images/pc/img_bs_sq.png') no-repeat;
    background-size: 100%;
  }
  .bottom_bg {
    position: absolute;
    bottom: 0px;
    right: 0;
    height: 425px;
    width: 462px;
   background: url('#{$cdnUrl}/assets/images/pc/img_bs_xq.png') no-repeat;
    background-size: 100%;
  }
  .top_logo {
    position: absolute;
    right: 50px;
    top: 110px;
    width: 916px;
    height: 100px;
   background: url('#{$cdnUrl}/assets/images/pc/img_bs.png') no-repeat;
    background-size: 100%;
  }
  .lists {
    padding-top: 140px;
    width: 62.5%;
    min-height: 1000px;
    margin: 0 auto;
    position: relative;
    .new-list-tab {
      display: flex;
      flex-wrap: nowrap;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      position: absolute;
      top: 140px;
      left: 0;
      z-index: 99;
      .tab-items {
        width: 140px;
        height: 50px;
        cursor: pointer;
        margin-right: 10px;
      }
      .tab-items0 {
        background: url('#{$cdnUrl}/assets/images/pc/latest.png') no-repeat
          bottom;
        background-size: 100%;
      }
      .tab-items1 {
        background: url('#{$cdnUrl}/assets/images/pc/part-three-news.png') no-repeat
          bottom;
        background-size: 100%;
      }
      .tab-items2 {
        background: url('#{$cdnUrl}/assets/images/pc/activity.png')
          no-repeat bottom;
        background-size: 100%;
      }
      .tab-items3 {
        background: url('#{$cdnUrl}/assets/images/pc/notice.png') no-repeat
          bottom;
        background-size: 100%;
      }
      .tabactive0 {
        background: url('#{$cdnUrl}/assets/images/pc/latest-on.png')
          no-repeat bottom;
        background-size: 100%;
      }
      .tabactive1 {
        background: url('#{$cdnUrl}/assets/images/pc/news-on.png') no-repeat
          bottom;
        background-size: 100%;
      }
      .tabactive2 {
        background: url('#{$cdnUrl}/assets/images/pc/activity-on.png')
          no-repeat bottom;
        background-size: 100%;
      }
      .tabactive3 {
        background: url('#{$cdnUrl}/assets/images/pc/notice-on.png')
          no-repeat bottom;
        background-size: 100%;
      }
    }
    .list-main {
      margin-top: 75px;
    }
    .list-item {
      padding: 25px;
      width: 100%;
      height: 222px;
      display: flex;
      flex-wrap: nowrap;
     background: url('#{$cdnUrl}/assets/images/pc/img_bs_mainban.png') no-repeat;
      color: #fff;
      font-size: 24px;
      margin-bottom: 20px;
      .news-img {
        width: 325px;
      }
      label {
        color: #db64ff;
        padding-left: 60px;
      }
      .list-item-right {
        margin-left: 40px;
        width: 68%;
        cursor: pointer;
      }
      .text {
        width: 99%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: inline-block;
        cursor: pointer;
      }
      .time {
        color: #baa3c7;
      }
      .item-bottom {
        font-size: 22px;
        float: right;
        margin-top: 100px;
      }
    }
    .more {
      width: 127px;
      height: 66px;
     background: url('#{$cdnUrl}/assets/images/pc/more.png') no-repeat;
      background-size: 100%;
      margin: 20px auto;
      cursor: pointer;
    }
  }
  .pc-bottom {
    bottom: -270px;
  }
}
</style>
