<template>
  <transition :name="transitionName">
    <div
      v-show="visible"
      class="back-to-ceiling"
      :class="ispc ? 'pc-style' : 'mobile-style'"
      @click="backToTop"></div>
  </transition>
</template>

<script>
export default {
  name: 'BackToTop',
  props: {
    visibilityHeight: {
      type: Number,
      default: 400,
    },
    backPosition: {
      type: Number,
      default: 0,
    },
    transitionName: {
      type: String,
      default: 'fade',
    },
    ispc: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      visible: false,
      interval: null,
      isMoving: false,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
  methods: {
    handleScroll() {
      this.visible = window.pageYOffset > this.visibilityHeight;
    },
    backToTop() {
      if (this.isMoving) return;
      const start = window.pageYOffset;
      let i = 0;
      this.isMoving = true;
      this.interval = setInterval(() => {
        const next = Math.floor(this.easeInOutQuad(10 * i, start, -start, 500));
        if (next <= this.backPosition) {
          window.scrollTo(0, this.backPosition);
          clearInterval(this.interval);
          this.isMoving = false;
        } else {
          window.scrollTo(0, next);
        }
        i++;
      }, 16.7);
    },
    easeInOutQuad(t, b, c, d) {
      if ((t /= d / 2) < 1) return (c / 2) * t * t + b;
      return (-c / 2) * (--t * (t - 2) - 1) + b;
    },
  },
};
</script>

<style lang="scss" scoped>
.back-to-ceiling {
  position: absolute;
  cursor: pointer;
}
.pc-style {
  width: 61px;
  height: 76px;
  background: url('#{$cdnUrl}/assets/images/pc/to_top.png') no-repeat;
  background-size: 100%;
  right: 50px;
  bottom: 50px;
}
.mobile-style {
  width: 0.275rem;
  height: 0.31rem;
  background: url('#{$cdnUrl}/assets/images/mobile/btn_bs_top.png') no-repeat;
  background-size: 100%;
  left:50% ;
  /* bottom: 0.42rem; */
  bottom: 1rem;
  transform: translate(-50%, 0);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.back-to-ceiling .Icon {
  fill: #9aaabf;
  background: none;
}
</style>
