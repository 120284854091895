<template>
  <div class="home">
    <top-nav
      @change="navChange"
      :active="activeNav"></top-nav>
    <div
      class="home-main"
      id="home">
      <video
        id="myPlayer"
        class="video-js vjs-default-skin vjs-big-play-centered"
        playsinline
        webkit-playsinline
        loop
        controls
        muted
        preload="auto"
        autoplay="autoplay"
        controlsList="nodownload"
        oncontextmenu="return false">
      </video>
      <div class="home-mask"></div>
      <div class="home-center">
        <div
          class="video-play"
          @click="videoPlay">
          <div class="video-play-circle animate"></div>
          <div class="video-play-btn"></div>
        </div>
        <div class="ewm-main">
          <div class="ewm-bg">
            <div class="ewm-img"></div>
          </div>
          <div class="ewm-btns">
            <div
              v-for="(item, index) in channelList"
              :key="index"
              :class="['ewm-btn', item.name]"
              @click="handleClick(item)"></div>
          </div>
        </div>
      </div>
      <video-player
        ref="videoPlayer"
        :isShow="isShow"
        @close="closePlayer"></video-player>
      <div class="mask bottom-mask"></div>
    </div>
    <div
      class="einherjar-main"
      id="einherjar">
      <div class="mask top-mask"></div>
      <div class="title title-two"></div>
      <swiper
        :option="swiperOption"
        circular="true"
        loop="true"
        ref="mySwiper">
        <swiper-slide
          :class="['banner-item', `banner-item-${item.name}`]"
          v-for="(item, index) in bannerlist"
          :key="index">
          <span>{{ item.info }}</span>
          <div :class="['gif', `gif-${item.name}`]"></div>
        </swiper-slide>
      </swiper>
      <div
        class="swiper-pagination"
        slot="pagination">
        <div
          v-for="(item, index) in bannerlist"
          :key="index"
          :class="[
            activeIndex === index
              ? `btn-${item.name}-active`
              : `btn-${item.name}`,
            'btn',
          ]"
          @mouseenter="mouseenter(index)"
          @mouseleave="mouseleave(index)"></div>
      </div>
      <div class="mask bottom-mask"></div>
    </div>
    <div
      class="news-main"
      id="news">
      <div class="mask top-mask"></div>
      <div class="title title-three"></div>

      <div class="news-content">
        <div class="news-bg">
          <div
            class="more"
            @click="handleMore"></div>
          <div class="new-list-tab">
            <div
              v-for="(item, index) in tabList"
              :key="index"
              :class="[
                'tab-items',
                `tab-items${index}`,
                tabIndex === index ? `tabactive${index}` : '',
              ]"
              @click="tabClick(index)"></div>
          </div>
          <transition name="slide-fade">
            <div
              class="list-main"
              v-if="showList">
              <div
                class="list-item"
                v-for="(item, index) in newslist"
                :key="index">
                <div
                  class="list-item-left"
                  @click="handleItem(item)">
                  <div class="time">{{ item.published_at }}</div>
                  <label>[{{ tabList[tabIndex].name }}]</label>
                  <span class="text">
                    {{ item.name }}
                  </span>
                  <div
                    :class="[
                      'item-icon',
                      item.flag == 1
                        ? 'item-hot'
                        : item.flag == 2
                        ? 'item-new'
                        : '',
                    ]"></div>
                </div>
                <!-- <div class="time">{{ item.time }}</div> -->
              </div>
            </div>
          </transition>
        </div>
        <swiper
          class="myNewsSwiper"
          :option="newsSwiperOption"
          ref="myNewsSwiper">
          <swiper-slide
            class="new-item"
            v-for="(item, index) in newsBanner"
            :key="index">
            <img
              :src="item.pic"
              style="width: 100%; height: 100%"
              @click="handelChangeUrl(item)" />
          </swiper-slide>
        </swiper>

        <div
          class="swiper-pagination"
          slot="pagination">
          <div
            v-for="(item, index) in newsBanner"
            :key="index"
            :class="[newsActive === index ? `news-btn-active` : '', 'news-btn']"
            @mouseenter="newsBannerEnter(index)"
            @mouseleave="newsBannerLeave(index)"></div>
        </div>
      </div>
    </div>
    <div
      class="community-main"
      id="community">
      <div class="mask top-mask"></div>
      <div class="title title-four"></div>
      <div class="center">
        <div
          v-for="(item, index) in linklist"
          :key="index"
          :class="['center-item', `center-${item.name}`]"
          @click="handleClick(item)"></div>
      </div>
    </div>
    <index-footer></index-footer>
    <back-to-top></back-to-top>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import videoJs from 'video.js';
import TopNav from '@/components/top-nav/index';
import BackToTop from '@/components/BackToTop/index';
import videoPlayer from '@/components/videoPlayer/index';
import IndexFooter from '@/components/Footer/index';
import { getBannerLists, getArticleLists } from '@/api/index';
import { isExternal } from '@/assets/js/utils';

export default {
  name: 'Home',
  data() {
    return {
      activeNav: 'home',
      bannerlist: [
        {
          name: 'one',
          info: 'Launches multiple feathers to pierce enemies, enemies hit by feathers will pause for a short time.',
        },
        {
          name: 'two',
          info: "Nine-Tails Sweep: When shooting, use the fox tail to sweep the enemy and reduce the enemy's attack strength",
        },
        {
          name: 'three',
          info: 'After rolling, it launches a shock wave centered on itself, causing damage to enemies and recovering part of its rolling energy',
        },
        {
          name: 'four',
          info: 'When transformed, launches Ship Collision to the front and creates a vortex after the collision, continuously dealing damage, and draws enemies.',
        },
        {
          name: 'five',
          info: 'Warrior Matchless: When shooting, use the painted halberd to perform a triple action; At the same time, it restores 5% of its maximum HP; the damage of the skill is greatly increased during the transformation',
        },
      ],
      channelList: [
        {
          name: 'android',
          url: 'https://play.google.com/store/apps/details?id=cc.k2games.burstspace',
        },
        {
          name: 'ios',
          url: '',
        },
      ],
      activeIndex: 0,
      swiperOption: {
        // loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        initialSlide: 1,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          paginationClickable: true,
        },
        observer: true,
        observerParents: true,
      },
      newsSwiperOption: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
      timer: null,
      newsTimer: null,
      isShow: false,
      linklist: [
        {
          name: 'facebook',
          url: 'https://www.facebook.com/burstspacegroup',
        },
        {
          name: 'youtube',
          url: 'https://www.youtube.com/@burstspaceglobal',
        },
        {
          name: 'tiktok',
          url: 'https://www.tiktok.com/@burstspace',
        },
      ],
      tabIndex: 0,
      newsActive: 0,
      tabList: [
        { name: 'LATEST' },
        {
          name: 'NEWS',
        },
        {
          name: 'ACTIVITY',
        },
        {
          name: 'NOTICE',
        },
      ],
      newslist: [],
      newsBanner: [],
      showList: true,
    };
  },
  components: {
    TopNav,
    BackToTop,
    swiper,
    swiperSlide,
    videoPlayer,
    IndexFooter,
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
    myNewsSwiper() {
      return this.$refs.myNewsSwiper.swiper;
    },
  },
  mounted() {
    const innerheight = window.innerHeight;
    document.getElementById('home').style.height = innerheight + 'px';
    document.getElementById('einherjar').style.height = innerheight + 'px';
    document.getElementById('news').style.height = innerheight + 'px';
    this.playMyVideo();
    this.timer = setInterval(() => {
      this.autoplay();
    }, 4000);
    this.swiper.allowTouchMove = false;
    this.myNewsSwiper.allowTouchMove = false;
    if (this.$route.query.navname) {
      this.scrollView(this.$route.query.navname);
    }
    this.getBannerLists();
    this.getArticleLists(0);
  },
  destroyed() {
    clearInterval(this.timer);
    clearInterval(this.newsTimer);
    videoJs('myPlayer').dispose();
  },
  methods: {
    handleChannel(item) {
      if (item.url !== '') {
        window.open(item.url);
      }
    },
    playMyVideo() {
      videoJs('myPlayer').src([
        {
          type: 'video/mp4',
          src: 'https://cdn.k2games.cc/assets/images/video/homeVideo.mp4',
        },
      ]);
      videoJs('myPlayer').play();
    },
    navChange(item) {
      this.$router.replace({
        path: this.$route.path,
        query: {
          navname: item.name,
        },
      });
      this.scrollView(item.name);
    },
    scrollView(item) {
      this.activeNav = item;
      const element = document.getElementById(item);
      element.scrollIntoView({
        behavior: 'smooth', // 平滑过渡
        block: 'start', // 上边框与视窗顶部平齐。默认值
      });
    },
    mouseenter(index) {
      clearInterval(this.timer);
      this.activeIndex = index;
      this.swiper.slideTo(index, 10, false);
    },
    mouseleave() {
      this.timer = setInterval(() => {
        this.autoplay();
      }, 4000);
    },
    autoplay() {
      if (0 < this.activeIndex < 5) {
        this.activeIndex++;
        if (this.activeIndex >= 5) {
          this.activeIndex = 0;
        }
      }
      this.swiper.slideTo(this.activeIndex, 10, false);
    },

    autoNewsBannerplay() {
      if (0 < this.newsActive < this.newsBanner.length) {
        this.newsActive++;
        if (this.newsActive >= this.newsBanner.length) {
          this.newsActive = 0;
        }
      }
      this.myNewsSwiper.slideTo(this.newsActive, 10, false);
    },
    newsBannerEnter(index) {
      clearInterval(this.newsTimer);
      this.newsActive = index;
      this.myNewsSwiper.slideTo(index, 10, false);
    },
    newsBannerLeave() {
      this.newsTimer = setInterval(() => {
        this.autoNewsBannerplay();
      }, 4000);
    },
    videoPlay() {
      this.isShow = true;
      setTimeout(() => {
        this.$refs.videoPlayer.playVideo();
      }, 200);
    },
    closePlayer() {
      this.isShow = false;
    },
    handleClick(item) {
      if (item.url !== '') {
        window.open(item.url);
      }
    },
    tabClick(index) {
      this.tabIndex = index;
      this.showList = false;
      this.getArticleLists(index);
    },
    handleItem(item) {
      this.$router.push({
        path: '/info',
        query: {
          id: item.id,
        },
      });
    },
    getBannerLists() {
      getBannerLists({
        game_id: 1,
        page: 1,
        limit: 10,
      }).then((res) => {
        this.newsBanner = res.data.list;
        if (this.newsBanner.length >= 2) {
          this.newsBannerLeave();
        }
      });
    },
    getArticleLists(type) {
      this.newslist = [];
      getArticleLists({
        game_id: 1,
        type: type,
        page: 1,
        limit: 6,
      }).then((res) => {
        this.newslist = res.data.list.map((v) => ({
          published_at: v.published_at.slice(0, 10),
          name: v.name,
          id: v.id,
          flag: v.flag,
        }));
        this.showList = true;
      });
    },
    handleMore() {
      this.$router.push('/newslist');
    },
    handelChangeUrl(item) {
      if (item.url_type === 2) {
        let url = '';
        isExternal(item.url) ? (url = item.url) : (url = `http://${item.url}`);
        window.open(url, '_blank');
      } else {
        this.$router.push(item.url);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  position: relative;
  .home-main {
    width: 100%;
    height: 1080px;
    position: relative;
  }
  .title {
    height: 89px;
    margin-left: 45px;
  }
  .mask {
    width: 100%;
    height: 136px;
    position: absolute;
    left: 0;
  }
  .bottom-mask {
    bottom: 0;
    background: url('#{$cdnUrl}/assets/images/pc/img_bs_main1_mask2.png')
      no-repeat;
    background-size: cover;
    z-index: 100;
  }
  .top-mask {
    top: 0;
    background: url('#{$cdnUrl}/assets/images/pc/img_bs_main2_mask2.png')
      no-repeat;
    background-size: cover;
    z-index: 98;
  }
  .home-mask {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: url('#{$cdnUrl}/assets/images/pc/img_bs_main1_mask.png')
      no-repeat;
    background-size: cover;
    z-index: 99;
  }
  .swiper-pagination {
    position: absolute;
    display: flex;
    z-index: 999;
    left: 50%;
    transform: translate(-50%, 0%);
  }
  .video-js {
    width: 100%;
    height: 100%;
  }
  .vjs-tech {
    object-fit: cover;
  }
  .home-center {
    position: absolute;
    bottom: 155px;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 99;
    .video-play {
      margin-bottom: 56px;
      cursor: pointer;
      position: relative;
    }
    .video-play-circle {
      width: 163px;
      height: 163px;
      background: url('#{$cdnUrl}/assets/images/pc/play-circle.png') no-repeat;
      background-size: cover;
      margin: 0 auto;
    }
    .video-play-btn {
      width: 55px;
      height: 70px;
      background: url('#{$cdnUrl}/assets/images/pc/play-btn.png') no-repeat
        center center;
      background-size: 75%;
      cursor: pointer;
      position: absolute;
      margin-left: 3px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .ewm-main {
      display: flex;
      .ewm-bg {
        width: 196px;
        height: 196px;
        background: url('#{$cdnUrl}/assets/images/pc/erwm-bg.png') no-repeat;
        background-size: 100%;
        margin: 7px 54px 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .ewm-img {
        background: url('#{$cdnUrl}/assets/images/pc/ewm.png') no-repeat;
        background-size: 100%;
        width: 100%;
        height: 100%;
      }
      .ewm-btn {
        width: 325px;
        height: 90px;
        cursor: pointer;
      }
      .android {
        background: url('#{$cdnUrl}/assets/images/pc/android.png') no-repeat;
        background-size: 100%;
      }
      .ios {
        margin-top: 30px;
        background: url('#{$cdnUrl}/assets/images/pc/ios.png') no-repeat;
        background-size: 100%;
      }
    }
  }
  .einherjar-main {
    width: 100%;
    // height: 1080px;
    background: url('#{$cdnUrl}/assets/images/pc/part-two.bg.png') no-repeat;
    background-size: cover;
    position: relative;
    .title-two {
      width: 320px;
      background: url('#{$cdnUrl}/assets/images/pc/part-two-title.png') no-repeat;
      background-size: 100%;
      position: absolute;
      top: 95px;
      z-index: 999;
    }
    .swiper-container {
      height: 100%;
    }
    .banner-item {
      span {
        color: #fff;
        font-size: 30px;
        width: 35%;
        display: block;
        float: right;
        margin-top: 15%;
        margin-right: 5%;
      }
    }
    .banner-item-one {
      background: url('#{$cdnUrl}/assets/images/pc/img-one.png') no-repeat;
      background-size: cover;
    }
    .banner-item-two {
      background: url('#{$cdnUrl}/assets/images/pc/img-two.png') no-repeat;
      background-size: cover;
    }
    .banner-item-three {
      background: url('#{$cdnUrl}/assets/images/pc/img-three.png') no-repeat;
      background-size: cover;
    }
    .banner-item-four {
      background: url('#{$cdnUrl}/assets/images/pc/img-four.png') no-repeat;
      background-size: cover;
    }
    .banner-item-five {
      background: url('#{$cdnUrl}/assets/images/pc/img-five.png') no-repeat;
      background-size: cover;
    }
    .gif {
      width: 160px;
      height: 180px;
      position: absolute;
      bottom: 220px;
      right: 26%;
    }
    .gif-one {
      background: url('#{$cdnUrl}/assets/images/pc/ayw.gif') no-repeat;
      background-size: 100%;
    }
    .gif-two {
      background: url('#{$cdnUrl}/assets/images/pc/yzq.gif') no-repeat;
      background-size: 100%;
    }
    .gif-three {
      width: 300px;
      height: 320px;
      background: url('#{$cdnUrl}/assets/images/pc/zd.gif') no-repeat;
      background-size: 100%;
      bottom: 180px;
      right: 21%;
    }
    .gif-four {
      width: 180px;
      height: 200px;
      bottom: 200px;
      background: url('#{$cdnUrl}/assets/images/pc/wdly.gif') no-repeat;
      background-size: 100%;
    }
    .gif-five {
      width: 260px;
      height: 280px;
      background: url('#{$cdnUrl}/assets/images/pc/lb.gif') no-repeat;
      background-size: 100%;
      bottom: 150px;
      right: 21%;
    }
    .swiper-pagination {
      bottom: 50px;
      .btn {
        width: 192px;
        height: 192px;
        cursor: pointer;
      }
      .btn-one {
        background: url('#{$cdnUrl}/assets/images/pc/btn_one.png') no-repeat;
        background-size: 100%;
      }
      .btn-one-active {
        background: url('#{$cdnUrl}/assets/images/pc/btn_one_active.png')
          no-repeat;
        background-size: 100%;
      }
      .btn-two {
        background: url('#{$cdnUrl}/assets/images/pc/btn_two.png') no-repeat
          center center;
        background-size: 100%;
      }
      .btn-two-active {
        background: url('#{$cdnUrl}/assets/images/pc/btn_two_active.png')
          no-repeat;
        background-size: 100%;
      }
      .btn-three {
        background: url('#{$cdnUrl}/assets/images/pc/btn_three.png') no-repeat
          center center;
        background-size: 100%;
      }
      .btn-three-active {
        background: url('#{$cdnUrl}/assets/images/pc/btn_three_active.png')
          no-repeat;
        background-size: 100%;
      }
      .btn-four {
        background: url('#{$cdnUrl}/assets/images/pc/btn_four.png') no-repeat
          center center;
        background-size: 100%;
      }
      .btn-four-active {
        background: url('#{$cdnUrl}/assets/images/pc/btn_four_active.png')
          no-repeat;
        background-size: 100%;
      }
      .btn-five {
        background: url('#{$cdnUrl}/assets/images/pc/btn_five.png') no-repeat
          center center;
        background-size: 100%;
      }
      .btn-five-active {
        background: url('#{$cdnUrl}/assets/images/pc/btn_five_active.png')
          center center no-repeat;
        background-size: 100%;
      }
    }
  }
  .news-main {
    width: 100%;
    background: url('#{$cdnUrl}/assets/images/pc/part-two.bg.png') no-repeat;
    background-size: cover;
    position: relative;
    overflow: hidden;
    padding-top: 95px;
    .title-three {
      width: 321px;
      background: url('#{$cdnUrl}/assets/images/pc/part-three-title.png') no-repeat;
      background-size: 100%;
    }
    .news-content {
      width: 100%;
      height: 60%;
      display: flex;
      align-items: center;
      top: 25%;
      left: 7%;
      position: absolute;
      z-index: 99;
    }
    .news-bg {
      width: 83%;
      background: url('#{$cdnUrl}/assets/images/pc/part-three-bg.png') no-repeat;
      background-size: cover;
      position: absolute;
      top: 24%;
      left: 6%;
      height: 75%;
    }
    .myNewsSwiper {
      width: 40%;
      height: 74%;
      margin: 3% 50px 0 0;
      cursor: pointer;
    }
    .swiper-pagination {
      left: 46%;
      bottom: 3%;
    }
    .news-btn {
      cursor: pointer;
      width: 12px;
      height: 12px;
      margin: 0 3px;
      background: url('#{$cdnUrl}/assets/images/pc/item.png') no-repeat;
    }
    .news-btn-active {
      width: 75px !important;
      background: url('#{$cdnUrl}/assets/images/pc/active.png') no-repeat !important;
    }
    .new-list-tab {
      display: flex;
      flex-wrap: nowrap;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      position: absolute;
      top: -17%;
      right: 3%;
      width: 53%;
      .tab-items {
        width: 140px;
        height: 50px;
        cursor: pointer;
        margin-right: 5%;
      }
      .tab-items0 {
        background: url('#{$cdnUrl}/assets/images/pc/latest.png') no-repeat
          bottom;
        background-size: 100%;
      }
      .tab-items1 {
        background: url('#{$cdnUrl}/assets/images/pc/part-three-news.png') no-repeat
          bottom;
        background-size: 100%;
      }
      .tab-items2 {
        background: url('#{$cdnUrl}/assets/images/pc/activity.png')
          no-repeat bottom;
        background-size: 100%;
      }
      .tab-items3 {
        background: url('#{$cdnUrl}/assets/images/pc/notice.png') no-repeat
          bottom;
        background-size: 100%;
      }
      .tabactive0 {
        background: url('#{$cdnUrl}/assets/images/pc/latest-on.png')
          no-repeat bottom;
        background-size: 100%;
      }
      .tabactive1 {
        background: url('#{$cdnUrl}/assets/images/pc/news-on.png') no-repeat
          bottom;
        background-size: 100%;
      }
      .tabactive2 {
        background: url('#{$cdnUrl}/assets/images/pc/activity-on.png')
          no-repeat bottom;
        background-size: 100%;
      }
      .tabactive3 {
        background: url('#{$cdnUrl}/assets/images/pc/notice-on.png')
          no-repeat bottom;
        background-size: 100%;
      }
    }
    .list-main {
      width: 51%;
      padding-bottom: 1%;
      position: absolute;
      right: 5%;
      top: 8%;
    }
    .list-item {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      color: #fff;
      font-size: 18px;
      border-bottom: 2px solid #db64ff;
      margin-bottom: 4%;
      position: relative;
      .time {
        width: 100px;
        color: #fff;
      }
      label {
        color: #db64ff;
        font-weight: bolder;
        padding: 0 20px 0 25px;
        font-size: 22px;
      }
      .list-item-left {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
      }
      .text {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: inline-block;
        cursor: pointer;
        width: 60%;
        font-size: 22px;
      }
      .item-icon {
        position: absolute;
        right: -105px;
        width: 100px;
        height: 60px;
      }
      .item-new {
       background: url('#{$cdnUrl}/assets/images/pc/new.gif') no-repeat;
        background-size: 50%;
      }
      .item-hot {
       background: url('#{$cdnUrl}/assets/images/pc/hot.gif') no-repeat;
        background-size: 50%;
      }
    }
    .more {
      position: absolute;
      width: 97px;
      height: 18px;
      background: url('#{$cdnUrl}/assets/images/pc/more.png') no-repeat !important;
      cursor: pointer;
      bottom: 3%;
      right: 3%;
      z-index: 999;
    }
  }
  .community-main {
    padding-top: 95px;
    width: 100%;
    height: 487px;
    background: url('#{$cdnUrl}/assets/images/pc/part-four-bg.png') no-repeat;
    background-size: cover;
    position: relative;
    .title-four {
      width: 346px;
      background: url('#{$cdnUrl}/assets/images/pc/title.png') no-repeat;
      background-size: 100%;
    }
    .center {
      display: flex;
      justify-content: center;
      margin-top: 40px;
      .center-item {
        width: 125px;
        height: 125px;
        margin: 0 40px;
        cursor: pointer;
      }
      .center-facebook {
       background: url('#{$cdnUrl}/assets/images/pc/bottom-facebook.png') no-repeat;
        background-size: 100%;
      }
      .center-youtube {
        background: url('#{$cdnUrl}/assets/images/pc/youtube.png') no-repeat;
        background-size: 100%;
      }
      .center-tiktok {
        background: url('#{$cdnUrl}/assets/images/pc/bottom-tiktok.png') no-repeat;
        background-size: 100%;
      }
    }
  }
}
</style>
