<template>
  <div class="info">
    <download-top></download-top>
    <div class="top_bg"></div>
    <div class="top_logo"></div>
    <div class="main">
      <div class="main-top">
        <div class="name">{{ info.name }}</div>
        <span class="time">{{ info.published_at }}</span>
      </div>
      <div v-html="info.content"></div>
    </div>
    <back-to-top
      :ispc="false"
      class="to-top"></back-to-top>
    <index-footer :ispc="false"></index-footer>
  </div>
</template>

<script>
import { getArticleInfo } from '@/api/index';
import BackToTop from '@/components/BackToTop/index';
import downloadTop from '@/components/download-top/index';
import IndexFooter from '@/components/Footer/index';

export default {
  name: 'Info',
  components: {
    BackToTop,
    downloadTop,
    IndexFooter,
  },
  data() {
    return {
      info: {},
      isPc: false,
    };
  },
  mounted() {
    getArticleInfo({
      id: this.$route.query.id,
    }).then((res) => {
      this.info = res.data;
    });
  },
};
</script>

<style lang="scss" scoped>
.info {
  background: url('#{$cdnUrl}/assets/images/mobile/bg_bs_new_bg.png') repeat top
    center;
  background-size: cover;
  overflow: hidden;
  width: 100%;
  position: relative;
  .top_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 0.95rem;
    height: 1.1rem;
    background: url('#{$cdnUrl}/assets/images/pc/img_bs_sq.png') no-repeat;
    background-size: 100%;
  }
  .top_logo {
    position: absolute;
    right: 0;
    top: 0.8rem;
    width: 2.29rem;
    height: 0.26rem;
    background: url('#{$cdnUrl}/assets/images/pc/img_bs.png') no-repeat;
    background-size: 100%;
  }
  .main {
    padding: 0.2rem;
    width: 95%;
    margin: 1.025rem auto 0.15rem auto;
    background: url('#{$cdnUrl}/assets/images/pc/center_bg.png') repeat;
    background-size: cover;
    color: #fff;
    min-height: 10rem;
    .main-top {
      margin-bottom: 0.1rem;
      padding: 0.05rem 0 0.15rem 0;
      border-bottom: 2px solid #c52aff;
      text-align: center;
      .name {
        font-size: 36px;
        margin-bottom: 0.1rem;
      }
      .time {
        font-size: 15px;
      }
    }
    img {
      width: 100% !important;
    }
  }
  .to-top {
    left: 93%;
    bottom: 1.55rem;
    z-index: 999;
  }
}
</style>
