<template>
  <div class="header-pc">
    <div class="nav-pc">
      <div
        class="logo"
        @click="goHome"></div>
      <div class="nav-right">
        <div class="nav-link">
          <a
            v-for="(item, index) in navlist"
            :key="index"
            :class="[
              active === item.name
                ? `nav-${item.name}-active`
                : `nav-${item.name}`,
              'nav-item',
            ]"
            @click="navChange(item)"></a>
        </div>
        <div
          class="order-btn"
          @click="goOrder"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopNav',
  props: {
    active: {
      type: String,
      default: 'home',
    },
  },
  data() {
    return {
      navlist: [
        { name: 'home' },
        { name: 'einherjar' },
        { name: 'news' },
        { name: 'community' },
      ],
    };
  },
  mounted() {},
  methods: {
    navChange(item) {
      // this.active = item.name;
      this.$emit('change', item);
    },
    goHome() {
      this.$router.push('/home');
    },
    goOrder() {
      fbq('track', 'Order', {});
      this.$router.push('/order');
    },
  },
};
</script>

<style lang="scss">
.header-pc {
  width: 100%;
  background: url('#{$cdnUrl}/assets/images/pc/head_top.png') top center
    no-repeat;
  height: 0.8rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  .nav-pc {
    margin: 0 auto;
  }
  .logo {
    width: 2.03rem;
    height: 1.4rem;
    margin: 0.3rem 0 0 0.3rem;
    cursor: pointer;
    background-image: url('#{$cdnUrl}/assets/images/pc/logo.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    float: left;
  }
  .nav-right {
    float: right;
    display: flex;
    margin-top: 0.2rem;
  }
  .nav-link {
    display: flex;
  }
  .order-btn {
    width: 2.91rem;
    height: 0.8rem;
    background: url('#{$cdnUrl}/assets/images/pc/pre-register.png') no-repeat;
    background-size: 100%;
    margin: 0 0.3rem 0 0.59rem;
    cursor: pointer;
  }
  .nav-item {
    width: 2.28rem;
    height: 0.8rem;
    cursor: pointer;
    margin-right: 0.5rem;
    transition: background 0.6s ease-in;
  }
  .nav-home {
    background: url('#{$cdnUrl}/assets/images/pc/home.png') no-repeat;
    background-size: 100%;
  }
  .nav-home-active {
    background: url('#{$cdnUrl}/assets/images/pc/home_active.png') no-repeat !important;
    background-size: 100% !important;
  }
  .nav-einherjar {
    background: url('#{$cdnUrl}/assets/images/pc/einherjar.png') no-repeat;
    background-size: 100%;
  }
  .nav-einherjar-active {
    background: url('#{$cdnUrl}/assets/images/pc/einherjar_active.png')
      no-repeat !important;
    background-size: 100% !important;
  }
  .nav-news {
    background: url('#{$cdnUrl}/assets/images/pc/news.png') no-repeat;
    background-size: 100%;
  }
  .nav-news-active {
    background: url('#{$cdnUrl}/assets/images/pc/news_active.png') no-repeat !important;
    background-size: 100% !important;
  }
  .nav-community {
    background: url('#{$cdnUrl}/assets/images/pc/community.png') no-repeat;
    background-size: 100%;
  }
  .nav-community-active {
    background: url('#{$cdnUrl}/assets/images/pc/community_active.png')
      no-repeat !important;
    background-size: 100% !important;
  }
}
</style>
