<template>
  <transition name="fade">
    <div
      :class="[!ispc ? 'zindex' : '', 'video-palyer-mask']"
      v-show="isShow">
      <div
        class="video-palyer"
        :style="{ height: height, width: width }">
        <i
          class="close"
          @click="close"></i>
        <video
          id="videoPlayer"
          class="video-js vjs-default-skin vjs-big-play-centered"
          playsinline
          webkit-playsinline
          controls
          muted
          loop
          preload="auto"
          autoplay="autoplay"
          controlsList="nodownload"
          oncontextmenu="return false">
          您的浏览器不支持 HTML5 video 标签。
        </video>
      </div>
    </div>
  </transition>
</template>

<script>
import videoJs from 'video.js';

export default {
  name: 'videoPlayer',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    ispc: {
      type: Boolean,
      default: true,
    },
    width: { type: String, default: '1000px' },
    height: { type: String, default: '600px' },
  },
  data() {
    return {
      videoPlayer: null,
    };
  },
  destroyed() {
    this.destroyVideo();
  },
  methods: {
    playVideo() {
      this.videoPlayer = videoJs('videoPlayer');
      this.videoPlayer.src([
        {
          type: 'video/mp4',
          src: 'https://cdn.k2games.cc/assets/images/video/homeVideo.mp4',
        },
      ]);
      this.videoPlayer.play();
    },
    close() {
      this.videoPlayer.pause();
      // videoJs('videoPlayer').load();
      this.$emit('close');
    },
    destroyVideo() {
      if (this.videoPlayer !== null) {
        this.videoPlayer.dispose();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.video-palyer-mask {
  background: url('#{$cdnUrl}/assets/images/mobile/img_bs_mask.png') no-repeat;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  &.zindex {
    z-index: 99 !important;
    .close {
      width: 0.25rem;
      height: 0.25rem;
    }
  }
  .video-palyer {
    transform: translate(-50%, -50%);
    z-index: 99;
    position: absolute;
    top: 50%;
    left: 50%;
  }
  .video-js {
    width: 100%;
    height: 100%;
  }
  .vjs-tech {
    object-fit: cover;
  }
  .close {
    width: 30px;
    height: 30px;
    display: block;
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
    background: url('#{$cdnUrl}/assets/images/pc/close.png') no-repeat;
    background-size: 100%;
    z-index: 100;
  }
}
</style>
